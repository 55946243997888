import useLabel from "@apps/use-label/use-label";

import AssessmentQuestionChrome from "./assessment-question-chrome";
import AssessmentQuestionHeading from "./assessment-question-heading";
import { getQuestionClass } from "./question";
import { AssessmentAnswer, AssessmentQuestion } from "./types";

const AssessmentQuestionItem = ({
  answer,
  formDisabled,
  question,
  showError,
  onUpdateAnswer,
}: {
  answer?: AssessmentAnswer;
  formDisabled: boolean;
  question: AssessmentQuestion;
  showError: boolean;
  onUpdateAnswer: (answer: AssessmentAnswer) => void;
}) => {
  const label = useLabel();
  const QuestionClass = getQuestionClass(question, { label });

  const questionAnswered =
    QuestionClass.isQuestionAnswered(answer) &&
    QuestionClass.hasEnteredComment(answer);

  const questionContent = QuestionClass.getForm({
    answer,
    onUpdateAnswer,
    formDisabled,
  });

  return (
    <AssessmentQuestionChrome
      key={question.id}
      isQuestionAnswered={!!questionAnswered}
      isShowingError={showError}
      aria-label={`Assessment question: ${question.title}`}
    >
      <AssessmentQuestionHeading question={question} />

      <div className="mt-4">{questionContent}</div>
    </AssessmentQuestionChrome>
  );
};

export default AssessmentQuestionItem;
