import { gql } from "@apollo/client";

export default gql`
  fragment ComplianceProgramReportAnswer on AssessmentAnswerInterface {
    id
    ... on RangeAssessmentAnswerNode {
      id
      questionId
      integerAnswer
      comment
      hasCalibrations
    }
    ... on IndividualGoalAssessmentAnswerNode {
      id
      questionId
      integerAnswer
      comment
    }
    ... on OverallGoalAssessmentAnswerNode {
      id
      questionId
      integerAnswer
      comment
    }
    ... on ResponsibilityAssessmentAnswerNode {
      id
      questionId
      integerAnswer
      comment
    }
    ... on CompetencyAssessmentAnswerNode {
      id
      questionId
      integerAnswer
      comment
    }
    ... on CompetencyCriteriaAssessmentAnswerNode {
      id
      questionId
      integerAnswer
      comment
    }
    ... on MultiChoiceAssessmentAnswerNode {
      id
      questionId
      choices
      comment
    }
    ... on TextAssessmentAnswerNode {
      id
      questionId
      textAnswer
      comment
    }
  }
  fragment ComplianceProgramReportAssessment on AssessmentNode {
    id
    state
    responder {
      id
      name
      firstName
      avatar
    }
    currentRoles {
      edges {
        node {
          id
          title
          level
          careerTrack {
            id
            title
          }
        }
      }
    }
    target {
      ...ComplianceProgramTarget
    }
    answers(questionType: [range]) {
      edges {
        node {
          ...ComplianceProgramReportAnswer
        }
      }
    }
    canRead {
      permission
    }
    assessmentDelivery {
      id
      state
      canUpdate {
        permission
      }
      deliveryDatetime
      canRead {
        permission
      }
    }
    template {
      id
      organization {
        id
      }
    }
    assessmentDeliveryState
  }
  fragment ComplianceProgramReportQuestion on AssessmentQuestionInterface {
    ... on RangeAssessmentQuestionNode {
      id
      title
      description
      startValue
      endValue
      labels
      questionType
      responses
      labelDescriptions
    }
    ... on CompetencyAssessmentQuestionNode {
      id
      title
      description
      startValue
      endValue
      labels
      questionType
      responses
      labelDescriptions
    }
    ... on CompetencyCriteriaAssessmentQuestionNode {
      id
      title
      description
      startValue
      endValue
      labels
      questionType
      responses
      labelDescriptions
    }
    ... on ResponsibilityAssessmentQuestionNode {
      id
      title
      description
      startValue
      endValue
      labels
      questionType
      responses
      labelDescriptions
    }
    ... on MultiChoiceAssessmentQuestionNode {
      id
      title
      description
      options
      questionType
      responses
    }
    ... on TextAssessmentQuestionNode {
      id
      title
      description
      questionType
      responses
    }
  }
  fragment ComplianceProgramTarget on UserNode {
    id
    name
    avatar
    organizationSpecificInfo {
      startDate
    }
    managers {
      edges {
        node {
          id
        }
      }
    }
    teams {
      edges {
        node {
          id
          title
        }
      }
    }
  }

  fragment ComplianceProgramForReportAssessmentGroupSection on AssessmentGroupSectionNode {
    id
    title
    description
    questions {
      edges {
        node {
          id
          question {
            ...ComplianceProgramReportQuestion
          }
        }
      }
    }
  }

  fragment ComplianceProgramForReportAssessmentGroup on AssessmentGroupNode {
    id
    title
    sections {
      edges {
        node {
          ...ComplianceProgramForReportAssessmentGroupSection
        }
      }
    }
  }

  query getComplianceProgramForReport(
    $complianceProgramId: Int!
    $assessmentType: AssessmentType!
    $isUpwardAssessmentReport: Boolean!
    $isPerformanceAssessmentReport: Boolean!
  ) {
    complianceProgram(pk: $complianceProgramId) {
      id
      title
      performanceAssessmentTemplate
        @include(if: $isPerformanceAssessmentReport) {
        id
        assessmentType
        isOnlySelfAssessment
        delivery
        questionSets {
          edges {
            node {
              ...ComplianceProgramForReportAssessmentGroup
            }
          }
        }
      }
      managerAssessmentTemplate @include(if: $isUpwardAssessmentReport) {
        id
        assessmentType
        isOnlySelfAssessment
        questionSets {
          edges {
            node {
              ...ComplianceProgramForReportAssessmentGroup
            }
          }
        }
      }
      assessments(state: submitted, assessmentType: $assessmentType) {
        edges {
          node {
            ...ComplianceProgramReportAssessment
          }
        }
      }
      managerEffectivenessReport @include(if: $isUpwardAssessmentReport) {
        edges {
          node {
            manager {
              ...ComplianceProgramTarget
              directReports {
                totalCount
              }
            }
            assessments {
              totalCount
            }
            questions {
              edges {
                node {
                  question {
                    id
                  }
                  avgAnswer
                }
              }
            }
          }
        }
      }
    }
  }
`;
