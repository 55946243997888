import { useMutation, useQuery } from "@apollo/client";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useCallback, useState } from "react";
import {
  AssessmentDeliveryState,
  GetAssessmentDeliveryQuery,
  GetAssessmentDeliveryQueryVariables,
  SaveAssessmentDeliveryMutation,
  SaveAssessmentDeliveryMutationVariables,
} from "types/graphql-schema";

import getAssessmentDelivery from "@apps/assessments/graphql/get-assessment-delivery";
import updateAssessmentDeliveryMutation from "@apps/assessments/graphql/update-assessment-delivery-mutation";
import { getHiddenQuestionCount } from "@apps/assessments/helpers";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button from "@components/button/button";
import CloseButton from "@components/close-button/close-button";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Sidebar from "@components/sidebar/sidebar";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { assertNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import { AssessmentDeliveryActions } from "../delivery/assessment-delivery";
import AssessmentDeliveryContent from "../delivery/assessment-delivery-content";
import AssessmentDeliveryHeader from "../delivery/assessment-delivery-header";

const AssessmentSidebar = ({
  assessmentDeliveryId,
  onClose,
}: {
  assessmentDeliveryId: number;
  onClose: () => void;
}) => {
  const orgranization = currentOrganizationVar();
  const label = useLabel();
  const [isShowingHiddenQuestions, setIsShowingHiddenQuestions] =
    useState(false);

  const { data, loading } = useQuery<
    GetAssessmentDeliveryQuery,
    GetAssessmentDeliveryQueryVariables
  >(getAssessmentDelivery, {
    fetchPolicy: "no-cache",
    variables: { assessmentDeliveryId, organizationId: orgranization.id },
    onError: onNotificationErrorHandler(),
  });
  const assessmentDelivery = data?.assessmentDelivery;
  const excludedAnswerIds = (data?.assessmentDelivery?.excludedAnswerIds ||
    []) as number[];

  const hiddenQuestionCount = assessmentDelivery
    ? getHiddenQuestionCount(assessmentDelivery)
    : 0;

  const {
    ConfirmationDialog: ReopenConfirmationDialog,
    confirm: confirmReopen,
  } = useConfirm(
    "Are you sure?",
    `Reopening the ${label(
      "review"
    )} delivery package will remove the subject's access until it is redelivered.`
  );

  const [saveAssessmentDelivery, { loading: isSaving }] = useMutation<
    SaveAssessmentDeliveryMutation,
    SaveAssessmentDeliveryMutationVariables
  >(updateAssessmentDeliveryMutation);

  const isDelivered =
    assessmentDelivery?.state === AssessmentDeliveryState.Delivered;

  const handleReopen = useCallback(async () => {
    const confirmation = await confirmReopen();
    if (confirmation) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId,
          state: AssessmentDeliveryState.Draft,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package reverted to in progress`,
          });
        },
        refetchQueries: [getAssessmentDelivery],
        onError: onNotificationErrorHandler(),
      });
    }
  }, [assessmentDeliveryId, confirmReopen, saveAssessmentDelivery, label]);

  return (
    <>
      <Sidebar show onClose={onClose}>
        {loading ? (
          <Loading>Loading assessment delivery...</Loading>
        ) : assessmentDelivery ? (
          <div className="flex flex-col gap-6 p-6 pt-0">
            <div className="flex justify-between items-center gap-4">
              <AppLink
                to={`/assessments/assessment/delivery/${assessmentDelivery.id}`}
                className="font-medium text-2xl hover:underline"
              >
                {getAssessmentTypeLabel(
                  assertNonNull(assessmentDelivery.template.assessmentType)
                )}{" "}
                {label("review")}:{" "}
                {assessmentDelivery.complianceProgram?.title || "Untitled"}
                <ExternalLinkIcon className="h-4 w-4 ml-1 text-gray-500 inline-block" />
              </AppLink>
              <CloseButton onClick={onClose} />
            </div>

            <AssessmentDeliveryHeader
              assessmentDeliveryData={data}
              hideHeading={true}
              onToggleShowHiddenQuestions={setIsShowingHiddenQuestions}
              isShowingHiddenQuestions={isShowingHiddenQuestions}
              hiddenQuestionCount={hiddenQuestionCount}
              isDelivered={isDelivered}
            />
            {assessmentDelivery.canUpdate?.permission &&
              assessmentDelivery.deliveryDatetime && (
                <div className="flex gap-6 justify-end items-center">
                  <div className="flex items-center gap-2">
                    <Button
                      className="mr-2"
                      disabled={isSaving}
                      onClick={handleReopen}
                    >
                      Reopen delivery package
                    </Button>
                    {isSaving && <Loading mini size="5" />}
                  </div>
                </div>
              )}
            <AssessmentDeliveryContent
              assessmentDeliveryData={data.assessmentDelivery}
              canUpdate={!!assessmentDelivery.canUpdate?.permission}
              isDraft={false}
              summary={assessmentDelivery.summary}
              showHiddenQuestions={isShowingHiddenQuestions}
              excludedAnswerIds={excludedAnswerIds}
            />
            <AssessmentDeliveryActions
              assessmentDeliveryData={data}
              isSaving={false}
              assessmentDeliveryId={assessmentDeliveryId}
            />
          </div>
        ) : (
          <div className="px-4 sm:px-6 flex flex-col gap-6 text-sm text-gray-500">
            Assessment delivery not found.
          </div>
        )}
      </Sidebar>
      <ReopenConfirmationDialog />
    </>
  );
};

export default AssessmentSidebar;
