import { gql } from "@apollo/client";

export default gql`
  fragment ComplianceProgramAssessmentGroup on AssessmentGroupNode {
    id
    title
    sections {
      edges {
        node {
          id
          title
          description
          questions {
            edges {
              node {
                id
                question {
                  ... on RangeAssessmentQuestionNode {
                    id
                    title
                    description
                    startValue
                    endValue
                    labels
                    questionType
                  }
                  ... on IndividualGoalAssessmentQuestionNode {
                    id
                    title
                    description
                    startValue
                    endValue
                    labels
                    questionType
                  }
                  ... on OverallGoalAssessmentQuestionNode {
                    id
                    title
                    description
                    startValue
                    endValue
                    labels
                    questionType
                  }
                  ... on CompetencyAssessmentQuestionNode {
                    id
                    title
                    description
                    startValue
                    endValue
                    labels
                    questionType
                  }
                  ... on CompetencyCriteriaAssessmentQuestionNode {
                    id
                    title
                    description
                    startValue
                    endValue
                    labels
                    questionType
                  }
                  ... on ResponsibilityAssessmentQuestionNode {
                    id
                    title
                    description
                    startValue
                    endValue
                    labels
                    questionType
                  }
                  ... on MultiChoiceAssessmentQuestionNode {
                    id
                    title
                    description
                    options
                    questionType
                  }
                  ... on TextAssessmentQuestionNode {
                    id
                    title
                    description
                    questionType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  query getComplianceProgramAssessmentAnswers(
    $complianceProgramId: Int!
    $includesUserMissingAssessments: Boolean!
    $assessmentType: AssessmentType!
    $selfAssessment: Boolean!
    $after: String
  ) {
    complianceProgram(pk: $complianceProgramId) {
      id
      performanceAssessmentTemplate {
        id
        assessmentType
        hasSelfAssessment
        questionSets {
          edges {
            node {
              ...ComplianceProgramAssessmentGroup
            }
          }
        }
      }
      managerAssessmentTemplate {
        id
        assessmentType
        hasSelfAssessment
        questionSets {
          edges {
            node {
              ...ComplianceProgramAssessmentGroup
            }
          }
        }
      }
      peerAssessmentTemplate {
        id
        assessmentType
        hasSelfAssessment
        questionSets {
          edges {
            node {
              ...ComplianceProgramAssessmentGroup
            }
          }
        }
      }
      assessments(
        state: submitted
        assessmentType: $assessmentType
        selfAssessment: $selfAssessment
        first: 20
        after: $after
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            state
            responder {
              id
              name
              avatar
            }
            target {
              id
              name
              avatar
            }
            answers {
              edges {
                node {
                  ... on RangeAssessmentAnswerNode {
                    id
                    questionId
                    integerAnswer
                  }
                  ... on IndividualGoalAssessmentAnswerNode {
                    id
                    questionId
                    integerAnswer
                  }
                  ... on OverallGoalAssessmentAnswerNode {
                    id
                    questionId
                    integerAnswer
                  }
                  ... on ResponsibilityAssessmentAnswerNode {
                    id
                    questionId
                    integerAnswer
                  }
                  ... on CompetencyAssessmentAnswerNode {
                    id
                    questionId
                    integerAnswer
                  }
                  ... on CompetencyCriteriaAssessmentAnswerNode {
                    id
                    questionId
                    integerAnswer
                  }
                  ... on MultiChoiceAssessmentAnswerNode {
                    id
                    questionId
                    choices
                  }
                  ... on TextAssessmentAnswerNode {
                    id
                    questionId
                    textAnswer
                  }
                }
              }
            }
            template {
              id
            }
          }
        }
      }
      usersMissingAssessment @include(if: $includesUserMissingAssessments) {
        edges {
          node {
            responder {
              id
              name
              avatar
            }
            target {
              id
              name
              avatar
            }
            assessmentTemplate {
              id
            }
          }
        }
      }
    }
  }
`;
