import { gql } from "@apollo/client";

import { ComplianceProgramActionAssessmentDeliveryFragment } from "@apps/programs/graphql/get-compliance-program-actions-query";

import { AssessmentNominationFragment } from "./get-assessment-nominations-query";

export default gql`
  fragment UnmetComplainceProgramAssessmentTemplate on AssessmentTemplateNode {
    id
    assessmentType
  }
  fragment UnmetComplianceProgram on ComplianceProgramNode {
    id
    title
    dueDate
    canReadDetails {
      permission
      reason
    }
    usersMissingAssessment {
      edges {
        node {
          target {
            id
            name
            avatar
          }
          responder {
            id
            name
            avatar
          }
          assessmentTemplateId
          dueDate
        }
      }
    }
    performanceAssessmentTemplate {
      ...UnmetComplainceProgramAssessmentTemplate
    }
    managerAssessmentTemplate {
      ...UnmetComplainceProgramAssessmentTemplate
    }
    peerAssessmentTemplate {
      ...UnmetComplainceProgramAssessmentTemplate
    }
    assessments(responderId: $currentUserId) {
      edges {
        node {
          id
          state
          target {
            id
            name
            avatar
          }
          template {
            id
          }
          submittedDatetime
        }
      }
    }
  }
  ${ComplianceProgramActionAssessmentDeliveryFragment}
  ${AssessmentNominationFragment}
  query getActiveReviewsActions(
    $organizationId: Int!
    $currentUserId: Int!
    $nominationTargetId: Int
    $deliveryTargetIds: [Int]!
  ) {
    compliancePrograms(
      onlyActive: true
      organizationId: $organizationId
      orderBy: "-due_date"
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...UnmetComplianceProgram
        }
      }
    }
    assessmentDeliveries(
      state: draft
      organizationId: $organizationId
      targetIds: $deliveryTargetIds
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ComplianceProgramActionAssessmentDelivery
        }
      }
    }
    assessmentsOpenForNominations(targetId: $nominationTargetId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...AssessmentNomination
        }
      }
    }
  }
`;
