import { gql } from "@apollo/client";

export default gql`
  query getAssessmentDeliveriesReceived(
    $organizationId: Int!
    $targetId: Int
    $after: String
    $orderBy: String!
  ) {
    combinedDeliveredAssessmentDeliveries(
      targetId: $targetId
      organizationId: $organizationId
      first: 10
      after: $after
      orderBy: $orderBy
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          deliveryDatetime
          lastSubmissionDatetime
          target {
            id
            name
            email
            firstName
            avatar
          }
          complianceProgram {
            id
            title
          }
          template {
            id
            assessmentType
          }
        }
      }
    }
  }
`;
