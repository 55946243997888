import { DotsHorizontalIcon } from "@heroicons/react/solid";
import {
  Menu,
  MenuDivider,
  MenuHeader,
  MenuItem,
  MenuRadioGroup,
  RadioChangeEvent,
} from "@szhsin/react-menu";
import { Fragment, ReactNode } from "react";

import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import { classNames } from "@helpers/css";

export type DropdownOptionButtonType = {
  type?: "button";
  label: string | ReactNode;
  description?: string | ReactNode;
  onClick: (data: { value?: any; syntheticEvent: any }) => void;
};
export type DropdownOptionLinkType = {
  type: "link";
  label: string | ReactNode;
  description?: string | ReactNode;
  to: string;
};
export type DropdownOptionDividerType = {
  type: "divider";
};
export type DropdownOptionRadioType = {
  type: "radio";
  label: string;
  value: boolean | string | number;
  onChangeValue: (e: RadioChangeEvent) => void;
  options: {
    label: string;
    value: boolean | string | number;
  }[];
};

export type DropdownOptionType =
  | DropdownOptionButtonType
  | DropdownOptionRadioType
  | DropdownOptionLinkType
  | DropdownOptionDividerType;

export const removeConsecutiveDividers = (
  arr: DropdownOptionType[]
): DropdownOptionType[] => {
  return arr.reduce<DropdownOptionType[]>((acc, item, index) => {
    if (index === 0 && item.type === "divider") {
      return acc;
    }
    if (index === arr.length - 1 && item.type === "divider") {
      return acc;
    }
    if (
      item.type === "divider" &&
      acc.length > 0 &&
      acc[acc.length - 1].type === "divider"
    ) {
      return acc; // Skip consecutive dividers
    }
    acc.push(item);
    return acc;
  }, []);
};

const Dropdown = ({
  options,
  className = "",
  children,
  align = "start",
  ...props
}: {
  children?: JSX.Element;
  "aria-label"?: string;
  align?: "start" | "end";
  className?: string;
  options: DropdownOptionType[];
}) => {
  const link = useLink();
  const cleanedOptions = removeConsecutiveDividers(options);
  const handleStopPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleClickLink = (e: any) => {
    if (e.syntheticEvent && !e.syntheticEvent.metaKey) {
      e.syntheticEvent.preventDefault();
      e.syntheticEvent.stopPropagation();
      link.redirect(e.value);
    }
  };
  return (
    <Menu
      className="relative inline-block text-left text-sm not-prose divide-y z-dropdown fs-unmask"
      portal
      align={align}
      menuButton={
        children ? (
          children
        ) : (
          <Button
            onClick={handleStopPropagation}
            icon={DotsHorizontalIcon}
            theme={buttonTheme.iconGray}
            aria-label={props["aria-label"]}
            className={classNames("text-gray-400 rounded", className)}
          />
        )
      }
    >
      {cleanedOptions.map((option, i) =>
        option.type === "divider" ? (
          <MenuDivider key={i} />
        ) : option.type === "radio" ? (
          <Fragment key={i}>
            <MenuHeader>{option.label}</MenuHeader>
            <MenuRadioGroup
              value={option.value}
              onRadioChange={option.onChangeValue}
            >
              {option.options.map((subOption, subOptionIndex) => (
                <MenuItem
                  key={String(subOption.value) || subOptionIndex}
                  type="radio"
                  value={subOption.value}
                >
                  {subOption.label}
                </MenuItem>
              ))}
            </MenuRadioGroup>
          </Fragment>
        ) : option.type === "link" ? (
          <MenuItem
            key={i}
            value={option.to}
            onClick={handleClickLink}
            href={option.to}
            className={classNames(
              "block w-full text-left px-4 py-2 text-sm",
              "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            )}
          >
            <div>{option.label}</div>
            {option.description && (
              <div className="text-gray-500 text-xs tracking-tight">
                {option.description}
              </div>
            )}
          </MenuItem>
        ) : (
          <MenuItem
            key={i}
            className={classNames(
              "block w-full text-left px-4 py-2 text-sm",
              "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            )}
            onClick={option.onClick}
          >
            <div>{option.label}</div>
            {option.description && (
              <div className="text-gray-500 text-xs tracking-tight">
                {option.description}
              </div>
            )}
          </MenuItem>
        )
      )}
    </Menu>
  );
};

export default Dropdown;
