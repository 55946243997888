import { gql } from "@apollo/client";

export default gql`
  fragment ComplianceProgramAdminParticipantStatus on ParticipantStatusNode {
    oneononeStatus
    performanceAssessmentStatus
    performanceSelfAssessmentStatus
    managerAssessmentStatus
    managerSelfAssessmentStatus
    peerAssessmentStatus
    peerSelfAssessmentStatus
    meeting {
      id
      title
      meetingGroupId
    }
    user {
      id
      name
      email
      avatar
      managers(first: 1) {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
    }
  }

  query getComplianceProgramAdmin($complianceProgramId: Int!) {
    complianceProgram(pk: $complianceProgramId) {
      id
      title
      created
      updated
      dueDate
      startDate
      periodStartDate
      periodEndDate
      ongoing
      startDateOffset
      startDateDueDateOffset
      startDatePeriodStartOffset
      startDatePeriodEndOffset
      state
      recurrence
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      canReadDetails {
        permission
        reason
      }
      appliesTo
      appliesToTeams {
        edges {
          node {
            id
            title
          }
        }
      }
      appliesToManagers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      appliesToUsers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      excludedUsers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      excludedHireDateAfter
      requiredTopicTemplates {
        edges {
          node {
            id
            title
          }
        }
      }
      performanceAssessmentTemplate {
        id
        assessmentType
        anonymity
        providers
        delivery
        hasSelfAssessment
        isOnlySelfAssessment
        questionSets {
          edges {
            node {
              id
            }
          }
        }
        enableRoleBasedAssessments
        enableGoalAssessments
        roleBasedAssessmentsAssessNextRole
        roleBasedAssessmentsSelfAssessNextRole
        roleBasedAssessmentScale
        roleBasedAssessmentScaleLabels
        roleBasedAssessmentScaleStartsAt
        competencyAssessmentType
        nextRoleCompetencyAssessmentType
      }
      managerAssessmentTemplate {
        id
        assessmentType
        anonymity
        providers
        delivery
        hasSelfAssessment
        isOnlySelfAssessment
        questionSets {
          edges {
            node {
              id
            }
          }
        }
      }
      peerAssessmentTemplate {
        id
        assessmentType
        anonymity
        providers
        delivery
        hasSelfAssessment
        isOnlySelfAssessment
        questionSets {
          edges {
            node {
              id
            }
          }
        }
      }
      participantStatus {
        edges {
          node {
            ...ComplianceProgramAdminParticipantStatus
          }
        }
      }
    }
  }
`;
