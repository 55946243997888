import { orderBy, range, reverse } from "lodash";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import {
  AssessmentGroupDelivery,
  AssessmentQuestionType,
  GetComplianceProgramForReportQuery,
  RangeAssessmentQuestionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { CareerTrackPickerTrack } from "@apps/org-settings/components/competencies/career-track-picker";
import { CareerTrackRolePickerRole } from "@apps/org-settings/components/competencies/career-track-role-picker";
import {
  matchesCareerTrackFilter,
  matchesCareerTrackLevelFilter,
  matchesReportsToFilter,
  matchesRoleFilter,
  matchesTeamsFilter,
  matchesUserFilter,
} from "@apps/reporting/helpers";
import useLabel from "@apps/use-label/use-label";
import Table, {
  TableBody,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableSortDir,
} from "@components/table/table";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import { PerformanceAssessmentAnswerFilter } from "../assessment-report";
import { TeamPickerTeam } from "../team-picker";
import PerformanceAssessmentTableRow from "./performance-assessment-table-row";

export type PerformanceAssessmentTableQuestion = Pick<
  RangeAssessmentQuestionNode,
  | "id"
  | "title"
  | "startValue"
  | "endValue"
  | "labels"
  | "questionType"
  | "labelDescriptions"
>;

enum SortType {
  KEY,
  QUESTION_ID,
}
enum SortKey {
  NAME,
  STATUS,
  ROLE,
  MANAGER,
  TEAM,
}
type Sort =
  | {
      type: SortType.KEY;
      key: SortKey;
    }
  | {
      type: SortType.QUESTION_ID;
      questionId: number;
    };

const PerformanceAssessmentTableHeader = ({
  currentSortKey,
  currentSortDir,
  columnSortKey,
  handleColumnSort,
  children,
}: PropsWithChildren<{
  currentSortKey: Sort;
  columnSortKey: Sort;
  currentSortDir: TableSortDir;
  handleColumnSort: (sort: Sort) => void;
}>) => {
  return (
    <TableHeadCell
      sorted={
        (currentSortKey.type === SortType.KEY &&
          columnSortKey.type === SortType.KEY &&
          currentSortKey.key === columnSortKey.key) ||
        (currentSortKey.type === SortType.QUESTION_ID &&
          columnSortKey.type === SortType.QUESTION_ID &&
          currentSortKey.questionId === columnSortKey.questionId)
          ? currentSortDir
          : undefined
      }
      onClick={() => handleColumnSort(columnSortKey)}
    >
      {children}
    </TableHeadCell>
  );
};

const PerformanceAssessmentTable = ({
  questions,
  complianceProgram,
  userFilterList,
  reportsToFilterList,
  answerFilterList,
  teamFilterList,
  roleFilterList,
  careerTrackFilterList,
  careerTrackLevelFilterList,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramForReportQuery["complianceProgram"]
  >;
  questions: PerformanceAssessmentTableQuestion[];
  userFilterList: BasicUser[];
  reportsToFilterList: BasicUser[];
  teamFilterList: TeamPickerTeam[];
  roleFilterList: CareerTrackRolePickerRole[];
  careerTrackFilterList: CareerTrackPickerTrack[];
  careerTrackLevelFilterList: number[];
  answerFilterList: PerformanceAssessmentAnswerFilter[];
}) => {
  const label = useLabel();
  const [sortKey, setSortKey] = useState<Sort>({
    type: SortType.KEY,
    key: SortKey.NAME,
  });
  const [sortDir, setSortDir] = useState<TableSortDir>("asc");
  const assessmentTemplate = assertNonNull(
    complianceProgram.performanceAssessmentTemplate
  );

  const assessments = useMemo(() => {
    return assertEdgesNonNull(complianceProgram.assessments).filter(
      (assessment) => assessment.template.id === assessmentTemplate.id
    );
  }, [assessmentTemplate, complianceProgram]);

  const showRoleColumn = useMemo(() => {
    return (
      waffle.flag_is_active("calibration") &&
      assessments.some((assessment) => {
        return assessment.currentRoles.edges.length > 0;
      })
    );
  }, [assessments]);

  const handleColumnSort = useCallback(
    (newSort: Sort) => {
      if (
        newSort.type !== sortKey.type ||
        (newSort.type === SortType.KEY &&
          sortKey.type === SortType.KEY &&
          newSort.key !== sortKey.key) ||
        (newSort.type === SortType.QUESTION_ID &&
          sortKey.type === SortType.QUESTION_ID &&
          newSort.questionId !== sortKey.questionId)
      ) {
        setSortKey(newSort);
        setSortDir("asc");
      } else {
        setSortDir(sortDir === "asc" ? "desc" : "asc");
      }
    },
    [sortDir, sortKey]
  );

  const sortedAssessments = useMemo(() => {
    const sorted = orderBy(assessments, (assessment) => {
      if (sortKey.type === SortType.KEY) {
        if (sortKey.key === SortKey.STATUS) {
          return assessment.assessmentDelivery
            ? assessment.assessmentDelivery.state
            : assessment.assessmentDeliveryState;
        }
        if (sortKey.key === SortKey.ROLE) {
          const roles = assertEdgesNonNull(assessment.currentRoles);
          return roles.length > 0
            ? roles
                .map((role) => `${role.title} (${role.careerTrack.title})`)
                .join(", ")
            : "";
        }
        if (sortKey.key === SortKey.MANAGER) {
          return assessment.responder?.name;
        }
        if (sortKey.key === SortKey.TEAM) {
          const teams = assertEdgesNonNull(assessment.target?.teams);
          return teams.length > 0
            ? teams.map(({ title }) => title).join(", ")
            : "";
        }
      }
      if (sortKey.type === SortType.QUESTION_ID) {
        const question = assertNonNull(
          questions.find(({ id }) => id === sortKey.questionId)
        );
        const answer = assertEdgesNonNull(assessment.answers).find(
          (answer) => answer.questionId === question.id
        );
        if (answer && answer.__typename === "RangeAssessmentAnswerNode") {
          const integerAnswer = answer.integerAnswer;
          const qLabels = range(
            question.startValue,
            question.endValue + 1
          ).reduce(
            (labelObj, value, index) => ({
              ...labelObj,
              [value]: question.labels[index] || value,
            }),
            {} as { [key: number]: string | number }
          );
          return integerAnswer ? qLabels[integerAnswer] : "";
        }
        return "";
      }
      return assessment.target?.name;
    });
    if (sortDir === "desc") {
      return reverse(sorted);
    }
    return sorted;
  }, [assessments, sortKey, sortDir, questions]);

  if (assessmentTemplate.isOnlySelfAssessment) {
    return (
      <div className="mx-auto my-12 text-center text-gray-400">
        {`This performance ${label(
          "review"
        )} only contains self assessment questions. There is no data to display.`}
      </div>
    );
  }
  const showAdminApprovalStatus =
    waffle.flag_is_active("calibration") &&
    assessmentTemplate.delivery === AssessmentGroupDelivery.AdminApproval;

  return (
    <TableContainer scroll>
      <Table>
        <TableHeadRow>
          <PerformanceAssessmentTableHeader
            currentSortKey={sortKey}
            currentSortDir={sortDir}
            handleColumnSort={handleColumnSort}
            columnSortKey={{ type: SortType.KEY, key: SortKey.NAME }}
          >
            Name
          </PerformanceAssessmentTableHeader>
          {showAdminApprovalStatus && (
            <PerformanceAssessmentTableHeader
              currentSortKey={sortKey}
              currentSortDir={sortDir}
              handleColumnSort={handleColumnSort}
              columnSortKey={{ type: SortType.KEY, key: SortKey.STATUS }}
            >
              Status
            </PerformanceAssessmentTableHeader>
          )}
          {showRoleColumn && (
            <PerformanceAssessmentTableHeader
              currentSortKey={sortKey}
              currentSortDir={sortDir}
              handleColumnSort={handleColumnSort}
              columnSortKey={{ type: SortType.KEY, key: SortKey.ROLE }}
            >
              Current Role
            </PerformanceAssessmentTableHeader>
          )}
          <PerformanceAssessmentTableHeader
            currentSortKey={sortKey}
            currentSortDir={sortDir}
            handleColumnSort={handleColumnSort}
            columnSortKey={{ type: SortType.KEY, key: SortKey.MANAGER }}
          >
            Manager
          </PerformanceAssessmentTableHeader>
          <PerformanceAssessmentTableHeader
            currentSortKey={sortKey}
            currentSortDir={sortDir}
            handleColumnSort={handleColumnSort}
            columnSortKey={{ type: SortType.KEY, key: SortKey.TEAM }}
          >
            {label("team", { pluralize: true, capitalize: true })}
          </PerformanceAssessmentTableHeader>
          {questions.map((question) => {
            if (question.questionType === AssessmentQuestionType.Text) {
              return null;
            }
            return (
              <PerformanceAssessmentTableHeader
                key={question.id}
                currentSortKey={sortKey}
                currentSortDir={sortDir}
                handleColumnSort={handleColumnSort}
                columnSortKey={{
                  type: SortType.QUESTION_ID,
                  questionId: question.id,
                }}
              >
                {question.title}
              </PerformanceAssessmentTableHeader>
            );
          })}
        </TableHeadRow>
        <TableBody>
          {sortedAssessments.map((assessment) => {
            const response = assessment;
            const responder = assertNonNull(response.responder);
            const target = assertNonNull(response.target);
            const answers = assertEdgesNonNull(response.answers);

            if (!matchesUserFilter(userFilterList, target)) {
              return null;
            }
            if (!matchesReportsToFilter(reportsToFilterList, target)) {
              return null;
            }
            if (!matchesTeamsFilter(teamFilterList, target)) {
              return null;
            }
            if (!matchesRoleFilter(roleFilterList, assessment)) {
              return null;
            }
            if (!matchesCareerTrackFilter(careerTrackFilterList, assessment)) {
              return null;
            }
            if (
              !matchesCareerTrackLevelFilter(
                careerTrackLevelFilterList,
                assessment
              )
            ) {
              return null;
            }
            if (responder.id === target.id) {
              // don't show self assessments for now
              return null;
            }
            if (
              answerFilterList.length > 0 &&
              !answerFilterList.every(
                (answerFilter) =>
                  !!answers.find(
                    (a) =>
                      a.questionId === answerFilter.questionId &&
                      a.__typename === "RangeAssessmentAnswerNode" &&
                      a.integerAnswer === answerFilter.integerAnswer
                  )
              )
            ) {
              return null;
            }
            return (
              <PerformanceAssessmentTableRow
                key={response.id}
                assessment={response}
                target={target}
                responder={responder}
                answers={answers}
                questions={questions}
                assessmentTemplate={assessmentTemplate}
                showRoleColumn={showRoleColumn}
                showAdminApprovalStatus={showAdminApprovalStatus}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PerformanceAssessmentTable;
