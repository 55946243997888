import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import moment from "moment";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ComplianceProgramRecurrence,
  ComplianceProgramState,
  GetComplianceProgramsQuery,
  GetComplianceProgramsQueryVariables,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import { AllDateRangeEnum } from "@apps/goal-alignment/goal-alignment";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import {
  currentOrganizationVar,
  currentUserVar,
  isAdminVar,
} from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import AppLabel from "@components/draft-label/app-label";
import DraftLabel from "@components/draft-label/draft-label";
import Input from "@components/input/input";
import Layout from "@components/layout/layout";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { Select, SelectOption } from "@components/select/select";
import ManagedTable from "@components/table/managed-table";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, dateRangeToDateArray } from "@helpers/helpers";

import getComplianceProgramsQuery from "../graphql/get-compliance-programs-query";
import ComplianceProgramActionDropdown from "./compliance-program-action-dropdown";
import ComplianceProgramRecurrencTooltip from "./compliance-program-recurrence-tooltip";

type DateRangeType = AllDateRangeEnum | DateRangeEnum;

const defaultDateRange = DateRangeEnum.thisQuarter;

const CompliancePrograms = () => {
  const organization = currentOrganizationVar();
  const location = useLocation();
  const currentUser = currentUserVar();
  const link = useLink();
  const isAdmin = isAdminVar();
  const label = useLabel();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const searchParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const debouncedSearch = useDebounce(search, 500);
  const defaultOnlyProgramsIAmPartOf =
    isAdmin && searchParams.has("onlyProgramsIAmPartOf")
      ? searchParams.get("onlyProgramsIAmPartOf") === "1"
      : uiPreferenceCache.programsShowOnlyMyProgramsFlag;
  const [onlyProgramsIAmPartOf, setOnlyProgramsIAmPartOf] = useState(
    defaultOnlyProgramsIAmPartOf
  );
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>(
    (searchParams.get("selectedDateRange") as DateRangeType) ||
      uiPreferenceCache.programsDateRangeDefault
  );
  const dateRanges =
    selectedDateRange === AllDateRangeEnum.all
      ? undefined
      : dateRangeToDateArray({
          range: selectedDateRange,
          quarterStartMonth: organization.quarterStartMonth,
        });

  const { data, loading, fetchMore } = useQuery<
    GetComplianceProgramsQuery,
    GetComplianceProgramsQueryVariables
  >(getComplianceProgramsQuery, {
    variables: {
      search: debouncedSearch,
      applicableUser: onlyProgramsIAmPartOf ? currentUser.id : undefined,
      organizationId: organization.id,
      dueDateGte: dateRanges ? dateRanges[0] : null,
      dueDateLte: dateRanges ? dateRanges[1] : null,
    },
    onError: onNotificationErrorHandler(),
  });

  const compliancePrograms = useMemo(() => {
    return data?.detailedCompliancePrograms
      ? assertEdgesNonNull(data.detailedCompliancePrograms)
      : [];
  }, [data]);

  const anytimeOption = {
    value: AllDateRangeEnum.all,
    label: `Due anytime`,
    selectedLabel: `Due anytime`,
    selected: selectedDateRange === null,
  };
  const dateRangeOptions = [
    anytimeOption,
    ...Object.values(DateRangeEnum).map((dateRange) => ({
      value: dateRange,
      label: label(dateRange, { capitalize: true }),
      selected: selectedDateRange === dateRange,
      description: dateRangeToDateArray({
        range: dateRange,
        quarterStartMonth: organization.quarterStartMonth,
      })
        .map((date) => moment(date).format("ll"))
        .join(" - "),
    })),
  ];

  const handleChangeDateRange = (option: SelectOption<DateRangeType>) => {
    setSelectedDateRange(option.value);
    saveUiPreference({ programsDateRangeDefault: option.value });
  };

  const handleChangeOnlyProgramsIAmPartOf = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const programsShowOnlyMyProgramsFlag = e.target.checked;
    setOnlyProgramsIAmPartOf(programsShowOnlyMyProgramsFlag);
    saveUiPreference({ programsShowOnlyMyProgramsFlag });
  };

  const handleClickLoadMore = () => {
    if (data?.detailedCompliancePrograms.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          after: data.detailedCompliancePrograms.pageInfo.endCursor,
          merge: true,
        },
      });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (search) {
      searchParams.set("search", search);
    }
    if (isAdmin && onlyProgramsIAmPartOf) {
      searchParams.set(
        "onlyProgramsIAmPartOf",
        onlyProgramsIAmPartOf ? "1" : "0"
      );
    }
    if (selectedDateRange !== defaultDateRange) {
      searchParams.set("selectedDateRange", selectedDateRange);
    }
    link.replace(
      `/programs${searchParams.toString() ? `?${searchParams.toString()}` : ""}`
    );
  }, [search, onlyProgramsIAmPartOf, selectedDateRange]);

  return (
    <Layout>
      <Layout.Header
        breadcrumbs={compact([{ title: "Programs", url: "/programs" }])}
      >
        {isAdmin && (
          <Button
            theme={buttonTheme.default}
            to="/programs/new"
            text="New program"
          />
        )}
      </Layout.Header>
      <Layout.Container>
        <Layout.Main fullWidth>
          <Layout.MainSection title="All Programs">
            <div className="">
              <div className="flex items-center gap-4 mb-4">
                <Input
                  className="max-w-48"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search..."
                />
                <Select<DateRangeType>
                  options={dateRangeOptions}
                  value={selectedDateRange}
                  onChange={handleChangeDateRange}
                />
                {isAdmin && (
                  <label className="flex items-center gap-1 text-sm cursor-pointer">
                    <input
                      type="checkbox"
                      checked={onlyProgramsIAmPartOf}
                      onChange={handleChangeOnlyProgramsIAmPartOf}
                    />
                    My programs
                  </label>
                )}
                {(loading || search !== debouncedSearch) && (
                  <Loading size={5} mini />
                )}
                <div className="flex-1" />
              </div>
              <div>
                <ManagedTable
                  sortingEnabled
                  sortKey="state"
                  sortDir="asc"
                  data={compliancePrograms}
                  rowClassName={(row) =>
                    row.state === ComplianceProgramState.Draft
                      ? "bg-gray-50"
                      : ""
                  }
                  emptyPlaceholder={
                    <div className="text-gray-500">No programs.</div>
                  }
                  columns={[
                    {
                      header: "Title",
                      field: "title",
                      sortKey: "title",
                      render: (row) => (
                        <div className="flex items-center gap-2">
                          <AppLink
                            className="text-blue-link hover:underline"
                            to={`/programs/${row.id}`}
                          >
                            {row.title}
                          </AppLink>
                          {row.recurrence !==
                            ComplianceProgramRecurrence.None && (
                            <ComplianceProgramRecurrencTooltip
                              recurrence={row.recurrence}
                            />
                          )}
                        </div>
                      ),
                    },
                    {
                      header: "State",
                      render: (row) =>
                        row.state === ComplianceProgramState.Draft ? (
                          <DraftLabel />
                        ) : row.state === ComplianceProgramState.Paused ? (
                          <AppLabel
                            label="Paused"
                            className="text-amber-900 bg-amber-200"
                          />
                        ) : (
                          <AppLabel
                            label="Published"
                            className="text-emerald-800 bg-emerald-200"
                          />
                        ),
                      sortKey: "state",
                    },
                    {
                      header: "Due date",
                      render: (row) =>
                        row.ongoing
                          ? "Ongoing"
                          : moment(row.dueDate).format("MMM D, YYYY"),
                      sortKey: "duedate",
                      value: (row) => moment(row.dueDate).format(),
                    },
                    {
                      header: "",
                      render: (row) => (
                        <div className="flex justify-end">
                          <ComplianceProgramActionDropdown
                            complianceProgram={row}
                          />
                        </div>
                      ),
                    },
                  ]}
                />
                {data?.detailedCompliancePrograms.pageInfo.hasNextPage && (
                  <div className="flex justify-center mt-6">
                    <Button text="Load more" onClick={handleClickLoadMore} />
                  </div>
                )}
              </div>
            </div>
          </Layout.MainSection>
        </Layout.Main>
      </Layout.Container>
    </Layout>
  );
};

export default CompliancePrograms;
