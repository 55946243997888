import { gql } from "@apollo/client";

export default gql`
  query getOrganizationCareerTrackRoles($organizationId: Int!) {
    careerRoles(organizationId: $organizationId) {
      edges {
        node {
          id
          title
          level
          careerTrack {
            id
            title
          }
        }
      }
    }
  }
`;
