import moment from "moment";
import { useMemo } from "react";
import { MdGridView } from "react-icons/md";
import {
  AssessmentDeliveryState,
  GetAssessmentDeliveryQuery,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import ToggleSwitch from "@components/toggle-switch/toggle-switch";
import InfoTooltip from "@components/tooltip/info-tooltip";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";

const AssessmentDeliveryHeader = ({
  assessmentDeliveryData,
  hideHeading = false,
  isDelivered,
  onToggleShowHiddenQuestions,
  isShowingHiddenQuestions,
  hiddenQuestionCount,
}: {
  assessmentDeliveryData?: GetAssessmentDeliveryQuery;
  hideHeading?: boolean;
  isDelivered?: boolean;
  onToggleShowHiddenQuestions: (isShowingHiddenQuestions: boolean) => void;
  isShowingHiddenQuestions: boolean;
  hiddenQuestionCount: number;
}) => {
  const label = useLabel();

  const assessmentDelivery = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery
        : null,
    [assessmentDeliveryData]
  );
  const program = useMemo(
    () => (assessmentDelivery ? assessmentDelivery.complianceProgram : null),
    [assessmentDelivery]
  );
  const canReadProgramDetails = useMemo(
    () => program?.canReadDetails.permission,
    [program]
  );
  const template = useMemo(
    () => (assessmentDelivery ? assessmentDelivery.template : null),
    [assessmentDelivery]
  );

  const target = useMemo(
    () =>
      assessmentDelivery ? assertNonNull(assessmentDelivery.target) : null,
    [assessmentDelivery]
  );

  if (!assessmentDeliveryData) {
    return null;
  }

  const leftCellClassName = "py-2 font-medium w-[200px]";
  const rightCellClassName = "py-2";
  const isDraft =
    assessmentDelivery?.state !== AssessmentDeliveryState.Delivered;

  const handleToggleShowHiddenQuestions = () => {
    onToggleShowHiddenQuestions(!isShowingHiddenQuestions);
  };

  return (
    <div className="flex flex-col text-sm gap-6 pb-6 mb-12 border-b">
      {!hideHeading && (
        <div className="flex items-center gap-2">
          <Avatar user={target} size={8} />
          <h2 className="text-2xl font-bold text-gray-800">{`${
            target?.name
          }: ${label("review", { capitalize: true })}`}</h2>
        </div>
      )}

      {hiddenQuestionCount > 0 && (
        <div className="flex items-center justify-between w-full gap-4 bg-blue-50 rounded-md p-4">
          <div className="text-sm flex items-center gap-2">
            <span>
              Show {target?.firstName || target?.name}'s perspective of this{" "}
              {label("review")}
            </span>
            <InfoTooltip
              text={`Use this setting to hide/show responses to questions that are not visible to the subject in this ${label(
                "review"
              )}.`}
            />
          </div>
          <div>
            <ToggleSwitch
              checked={!isShowingHiddenQuestions}
              onChange={handleToggleShowHiddenQuestions}
            />
          </div>
        </div>
      )}

      <div>
        <Layout.MainSubSection
          title={`${label("review", { capitalize: true })} Details`}
          collapsible
          defaultIsExpanded
        >
          <table className="text-gray-600 w-full">
            <tbody>
              <tr>
                <td className={leftCellClassName}>Program</td>
                {canReadProgramDetails ? (
                  <td className={rightCellClassName}>
                    <Button
                      to={`/programs/${program?.id}`}
                      theme={buttonTheme.text}
                      leftNegativeMargin
                    >
                      <MdGridView className="shrink-0 w-4 h-4" />
                      {program?.title}
                    </Button>
                  </td>
                ) : (
                  <td
                    className={classNames(
                      rightCellClassName,
                      "flex items-center gap-1"
                    )}
                  >
                    <MdGridView className="shrink-0 w-4 h-4" />
                    {program?.title}
                  </td>
                )}
              </tr>
              {!isDelivered && (
                <tr>
                  <td className={leftCellClassName}>{`${label("review", {
                    capitalize: true,
                  })} type`}</td>
                  <td className={rightCellClassName}>
                    {getAssessmentTypeLabel(
                      assertNonNull(template?.assessmentType)
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td className={leftCellClassName}>Subject</td>
                <td className={rightCellClassName}>
                  <div className="flex items-center">
                    <Avatar
                      className="mr-1"
                      user={assertNonNull(target)}
                      size="5"
                    />{" "}
                    {target?.name}
                  </div>
                </td>
              </tr>
              {target?.organizationSpecificInfo?.position && (
                <tr>
                  <td className={leftCellClassName}>Position</td>
                  <td className={rightCellClassName}>
                    {target?.organizationSpecificInfo?.position}
                  </td>
                </tr>
              )}
              {target?.organizationSpecificInfo?.jobLevel && (
                <tr>
                  <td className={leftCellClassName}>Job level</td>
                  <td className={rightCellClassName}>
                    {target?.organizationSpecificInfo?.jobLevel}
                  </td>
                </tr>
              )}

              <tr>
                <td className={leftCellClassName}>Program period</td>
                <td className={rightCellClassName}>
                  {program?.periodStartDate && program?.periodEndDate
                    ? `${moment(program.periodStartDate).format(
                        "MMM D, YYYY"
                      )} - ${moment(program.periodEndDate).format(
                        "MMM D, YYYY"
                      )}`
                    : "Not set"}
                </td>
              </tr>
              <tr>
                <td className={leftCellClassName}>Due date</td>
                <td className={rightCellClassName}>
                  {moment(assessmentDelivery?.dueDate).format("MMM D, YYYY")}
                </td>
              </tr>

              {!isDraft && (
                <tr>
                  <td className={leftCellClassName}>Delivery</td>
                  <td className={rightCellClassName}>
                    <div>
                      {assessmentDelivery.deliveryDatetime
                        ? `Delivered by ${
                            assessmentDelivery.creator?.name
                          } on ${moment(
                            assessmentDelivery.deliveryDatetime
                          ).format("MMM D, YYYY @ h:mma")}`
                        : "Immediate access"}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Layout.MainSubSection>
      </div>
    </div>
  );
};

export default AssessmentDeliveryHeader;
