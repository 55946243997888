import { withErrorBoundary } from "@sentry/react";
import { compact } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  PricingTier,
} from "types/graphql-schema";

import AskAI from "@apps/ask-ai/ask-ai";
import RelatedRequirements from "@apps/meeting-new/components/meeting-sidebar/related-requirements";
import WorkHistorySidebar from "@apps/work-history/components/work-history-sidebar";
import { currentOrganizationVar } from "@cache/cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";
import { ToggleButtonGroupType } from "@components/toggle-button-group/toggle-button-group";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import ActionItems from "./action-items";
import MeetingSidebarAIStats from "./ai-stats";
import MeetingSidebarAISugestions from "./ai-suggestions";
import Feedbacks from "./feedbacks";
import Goals from "./goals";
import MeetingSidebarMeetings from "./meetings";
import RecentlyCompletedAssessmentDeliveries from "./recently-completed-assessment-deliveries";
import Recognitions from "./recognitions";

enum MeetingSidebarTabEnum {
  topics = "Topics",
  meetings = "Meetings",
  artifacts = "Items",
  askAI = "Ask AI",
  stats = "Stats",
  suggestions = "Suggestions",
  workHistory = "Work history",
}

const MeetingViewSidebar = ({
  meeting,
  selectedTopicId,
  isInFullScreen,
  onClickTopic,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  isInFullScreen?: boolean;
  selectedTopicId?: number;
  onClickTopic?: (topic: { id: number }) => void;
}) => {
  const meetingGroup = meeting?.meetingGroup;
  const currentOrganization = currentOrganizationVar();
  const [activeTab, setActiveTab] = useState(
    isInFullScreen
      ? MeetingSidebarTabEnum.topics
      : MeetingSidebarTabEnum.artifacts
  );
  const [askAIChatSessionId, setAskAIChatSessionId] = useState<null | number>(
    null
  );

  const handleChangeActiveTab = (
    option: ToggleButtonGroupType<MeetingSidebarTabEnum>
  ) => {
    if (option.value) setActiveTab(option.value);
  };

  useEffect(() => {
    // when changing meeting, we reset Ask AI
    setAskAIChatSessionId(null);
  }, [meeting.id]);

  if (!meetingGroup) return null;

  const topics = assertEdgesNonNull(meeting.topics);

  const workHistoryStartDate =
    moment(meeting.previousMeeting?.startDate) ||
    moment().subtract(7, "days").format("YYYY-MM-DD");

  // If the end date is in the future, clamp the end date to today
  const meetingTimeOrNow = meeting.startDate
    ? moment(meeting.startDate)
    : moment();
  const workHistoryEndDate = meetingTimeOrNow.isAfter(moment().startOf("day"))
    ? moment()
    : meetingTimeOrNow;
  const workHistoryStartDateIsInFuture = workHistoryStartDate
    .startOf("day")
    .isAfter(moment().startOf("day"));

  return (
    <Layout.Sidebar<MeetingSidebarTabEnum>
      tabs={compact([
        isInFullScreen && {
          value: MeetingSidebarTabEnum.topics,
          title: MeetingSidebarTabEnum.topics,
          active: activeTab === MeetingSidebarTabEnum.topics,
        },
        {
          value: MeetingSidebarTabEnum.artifacts,
          title: MeetingSidebarTabEnum.artifacts,
          active: activeTab === MeetingSidebarTabEnum.artifacts,
        },
        {
          value: MeetingSidebarTabEnum.meetings,
          title: MeetingSidebarTabEnum.meetings,
          active: activeTab === MeetingSidebarTabEnum.meetings,
        },
        currentOrganization.featureFlags.workHistory &&
          meetingGroup.isFormalOneonone && {
            value: MeetingSidebarTabEnum.workHistory,
            title: MeetingSidebarTabEnum.workHistory,
            active: activeTab === MeetingSidebarTabEnum.workHistory,
          },
        currentOrganization.pricingTier === PricingTier.Ultimate && {
          value: MeetingSidebarTabEnum.askAI,
          title: MeetingSidebarTabEnum.askAI,
          active: activeTab === MeetingSidebarTabEnum.askAI,
        },
        {
          value: MeetingSidebarTabEnum.stats,
          title: MeetingSidebarTabEnum.stats,
          active: activeTab === MeetingSidebarTabEnum.stats,
        },
        {
          value: MeetingSidebarTabEnum.suggestions,
          title: MeetingSidebarTabEnum.suggestions,
          active: activeTab === MeetingSidebarTabEnum.suggestions,
        },
      ])}
      fullHeight={activeTab === MeetingSidebarTabEnum.askAI}
      onChangeTab={handleChangeActiveTab}
      sidebarExpandedUiPreferenceKey="meetingSidebarExpanded"
      aria-label="Meeting sidebar"
    >
      {activeTab === MeetingSidebarTabEnum.topics && onClickTopic && (
        <Layout.SidebarSection>
          <div className="flex flex-col gap-2 text-sm font-medium text-gray-500">
            {topics.map((topic) => (
              <div key={topic.id}>
                <button
                  className={classNames(
                    "hover:text-gray-900 text-left",
                    selectedTopicId === topic.id && "text-gray-900 font-bold"
                  )}
                  onClick={() => onClickTopic(topic)}
                >
                  {topic.title}
                </button>
              </div>
            ))}
          </div>
        </Layout.SidebarSection>
      )}
      {activeTab === MeetingSidebarTabEnum.artifacts &&
        meetingGroup.isFormalOneonone && (
          <RelatedRequirements meeting={meeting} />
        )}

      {activeTab === MeetingSidebarTabEnum.artifacts &&
        currentOrganization.featureFlags.actionItems && (
          <ActionItems meeting={meeting} />
        )}

      {activeTab === MeetingSidebarTabEnum.meetings && (
        <MeetingSidebarMeetings meeting={meeting} />
      )}

      {activeTab === MeetingSidebarTabEnum.artifacts &&
        currentOrganization.featureFlags.goals && <Goals meeting={meeting} />}

      {meetingGroup.isFormalOneonone &&
        activeTab === MeetingSidebarTabEnum.artifacts && (
          <RecentlyCompletedAssessmentDeliveries meeting={meeting} />
        )}

      {activeTab === MeetingSidebarTabEnum.artifacts &&
        currentOrganization.featureFlags.recognitions && (
          <Recognitions meeting={meeting} />
        )}

      {activeTab === MeetingSidebarTabEnum.artifacts &&
        currentOrganization.featureFlags.feedbacks &&
        meetingGroup.isFormalOneonone && <Feedbacks meeting={meeting} />}

      {activeTab === MeetingSidebarTabEnum.askAI && (
        <AskAI
          meetingId={meeting.id}
          chatSessionId={askAIChatSessionId}
          onChangeChatSessionId={setAskAIChatSessionId}
        />
      )}

      {activeTab === MeetingSidebarTabEnum.stats && (
        <Layout.SidebarSection>
          <MeetingSidebarAIStats meeting={meeting} />
        </Layout.SidebarSection>
      )}

      {activeTab === MeetingSidebarTabEnum.suggestions && (
        <Layout.SidebarSection>
          <MeetingSidebarAISugestions meeting={meeting} />
        </Layout.SidebarSection>
      )}

      {activeTab === MeetingSidebarTabEnum.workHistory && (
        <>
          {workHistoryStartDateIsInFuture ? (
            <div className="p-4">
              This meeting is too far into the future to have a work history,
              please check back later!
            </div>
          ) : (
            meeting.subject && (
              <WorkHistorySidebar
                userId={meeting.subject.id}
                startDate={workHistoryStartDate.format("YYYY-MM-DD")}
                endDate={workHistoryEndDate.format("YYYY-MM-DD")}
              />
            )
          )}
        </>
      )}
    </Layout.Sidebar>
  );
};

export default withErrorBoundary(MeetingViewSidebar, {
  fallback: (
    <Error description={"The meeting sidebar could not be displayed."} />
  ),
});
