import { useReactiveVar } from "@apollo/client";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { Slice } from "@tiptap/pm/model";
import { Editor, EditorContent, EditorOptions, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { compact, noop } from "lodash";
import { ReactElementLike } from "prop-types";
import { forwardRef, useEffect, useState } from "react";

import { currentTiptapJWTVar } from "@cache/cache";
import { classNames, inputBorderClassName } from "@helpers/css";
import { parseStringToJSON } from "@helpers/helpers";

import TipTapCustomImage, {
  WysiwygUploadVariable,
  asyncUploadImage,
} from "./extensions/image";
import CustomLink from "./extensions/link";
import CustomListItem from "./extensions/list-item";
import { getAiExtension } from "./helpers";

const defaultFn = noop;

type TextareaWysiwygProps = {
  deps?: any[];
  value?: JSON | string | null;
  autofocus?: boolean;
  editable?: boolean;
  placeholder?: string;
  className?: string;
  tiptapAiJwt?: string;
  overridePasteEvent?: boolean;
  imageUploadVariable?: WysiwygUploadVariable;
  renderAi?:
    | (({
        editor,
        aiLoading,
      }: {
        editor: Editor;
        aiLoading: boolean;
      }) => ReactElementLike)
    | null;
  onChangeValue?: (value: string, editor: Editor) => void;
  onFocus?: EditorOptions["onFocus"];
  onBlur?: EditorOptions["onBlur"];
};

export const TextareaWysiwygExtensions = [
  CustomLink.configure({
    openOnClick: true,
    autolink: true,
  }),
  StarterKit.configure({
    codeBlock: false,
    bulletList: false,
    orderedList: false,
    listItem: false,
  }),
  Underline,
  CustomListItem,
  BulletList.configure({
    itemTypeName: "listItem",
  }),
  OrderedList.configure({
    itemTypeName: "listItem",
  }),
  Typography,
];

const TextareaWysiwyg = forwardRef<Editor | null, TextareaWysiwygProps>(
  (
    {
      deps = [],
      value,
      overridePasteEvent,
      editable = false,
      autofocus = false,
      placeholder = "",
      className = "",
      imageUploadVariable,
      renderAi,
      onChangeValue,
      onFocus = defaultFn,
      onBlur = defaultFn,
    },
    ref
  ) => {
    const [aiLoading, setAiLoading] = useState(false);
    const enableAi = !!renderAi;
    const tiptapAiJwt = useReactiveVar(currentTiptapJWTVar);

    const jsonValue = parseStringToJSON(value);

    const content = !value // if falsy, return null
      ? null
      : typeof value !== "string" // if not a string, then it's JSON so we return the json
      ? value
      : jsonValue // if value can be parsed to JSON then return the parsed JSON value
      ? jsonValue
      : value; // or we just return the string value.

    const editor = useEditor(
      {
        editable,
        autofocus,
        extensions: compact([
          ...TextareaWysiwygExtensions,
          Placeholder.configure({
            placeholder,
          }),
          TipTapCustomImage(
            imageUploadVariable
              ? asyncUploadImage(imageUploadVariable)
              : undefined
          ),
          enableAi &&
            tiptapAiJwt &&
            getAiExtension(tiptapAiJwt, {
              onError: () => setAiLoading(false),
              onSuccess: () => setAiLoading(false),
              onLoading: () => setAiLoading(true),
            }),
        ]),
        editorProps: {
          attributes: {
            class: editable
              ? classNames(
                  "prose max-w-full px-4 py-2 text-sm",
                  inputBorderClassName,
                  className
                )
              : classNames("prose text-sm", className),
          },
          transformPasted: overridePasteEvent ? () => Slice.empty : undefined,
        },
        content,
        onFocus,
        onBlur,
      },
      [...deps, enableAi ? tiptapAiJwt : null]
    );
    if (ref && typeof ref !== "function") {
      ref.current = editor;
    }

    useEffect(() => {
      const handleUpdateValue = ({ editor }: any) => {
        if (onChangeValue)
          onChangeValue(JSON.stringify(editor.getJSON()), editor);
      };
      editor?.on("update", handleUpdateValue);
      return function cleanup() {
        editor?.off("update", handleUpdateValue);
      };
    }, [editor, onChangeValue]);

    return (
      <>
        <EditorContent editor={editor} className="flex-1 min-w-0" />
        {renderAi && editor ? renderAi({ editor, aiLoading }) : null}
      </>
    );
  }
);

export default TextareaWysiwyg;
