import { gql } from "@apollo/client";

export default gql`
  mutation updateAssessmentAnswer(
    $assessmentAnswerId: Int!
    $integerAnswer: Int!
    $comment: String!
  ) {
    createAssessmentAnswerCalibration(
      assessmentAnswerId: $assessmentAnswerId
      integerAnswer: $integerAnswer
      comment: $comment
    ) {
      answerCalibration {
        id
        ... on RangeAssessmentAnswerCalibrationNode {
          answer {
            id
            ... on RangeAssessmentAnswerNode {
              hasCalibrations
              comment
              integerAnswer
            }
          }
        }
      }
    }
  }
`;
