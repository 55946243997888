import { EyeOffIcon } from "@heroicons/react/outline";
import { AssessmentQuestionResponseVisibility } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";

import { getQuestionClass } from "./question";
import { AssessmentQuestion } from "./types";

const AssessmentQuestionHeading = ({
  question,
}: {
  question: AssessmentQuestion;
}) => {
  const label = useLabel();
  const QuestionClass = getQuestionClass(question, { label });
  const questionTitle = QuestionClass.getTitle();
  const questionDescription = QuestionClass.getDescription();
  return (
    <>
      {questionTitle}
      {question.responseVisibility ===
        AssessmentQuestionResponseVisibility.HiddenFromSubject && (
        <div className="text-xs text-gray-500 flex items-center">
          <EyeOffIcon className="text-gray-400 h-4 w-4 mr-1 shrink-0" />
          <div className="flex-1">The subject does not see this response</div>
        </div>
      )}
      {questionDescription}
    </>
  );
};

export default AssessmentQuestionHeading;
