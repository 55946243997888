import { PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

const AssessmentLayout = ({ children }: PropsWithChildren) => (
  <div className={classNames()}>{children}</div>
);

const AssessmentTypeSection = ({ children }: PropsWithChildren) => (
  <div className="border-b last:border-0 pb-12 mb-12">{children}</div>
);
const AssessmentTypeHeader = ({ children }: PropsWithChildren) => (
  <div className="flex-1 font-bold text-xl">{children}</div>
);
const ResponsesNotAvailable = ({ children }: PropsWithChildren) => (
  <div className="mt-8 p-6 bg-gray-100 text-sm text-gray-500 rounded-md">
    {children}
  </div>
);

const SectionHeading = ({ children }: PropsWithChildren) => (
  <div className="mb-4">{children}</div>
);
const SectionHeadingTitle = ({ children }: PropsWithChildren) => (
  <div className="font-semibold">{children}</div>
);
const SectionHeadingDescription = ({ children }: PropsWithChildren) => (
  <div className="mt-1 text-sm">{children}</div>
);

const SectionContainer = ({ children }: PropsWithChildren) => (
  <div className="mt-12 first:mt-0">{children}</div>
);
const SectionRegularQuestions = ({ children }: PropsWithChildren) => (
  <div className="border rounded-lg flex flex-col divide-y">{children}</div>
);

AssessmentLayout.AssessmentTypeSection = AssessmentTypeSection;
AssessmentLayout.AssessmentTypeHeader = AssessmentTypeHeader;
AssessmentLayout.ResponsesNotAvailable = ResponsesNotAvailable;
AssessmentLayout.SectionHeading = SectionHeading;
AssessmentLayout.SectionHeadingTitle = SectionHeadingTitle;
AssessmentLayout.SectionHeadingDescription = SectionHeadingDescription;
AssessmentLayout.SectionContainer = SectionContainer;
AssessmentLayout.SectionRegularQuestions = SectionRegularQuestions;

export default AssessmentLayout;
