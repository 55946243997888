import { useQuery } from "@apollo/client";
import { ArrowSmRightIcon, PencilIcon } from "@heroicons/react/outline";
import { range, sortBy } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import {
  GetAssessmentAnswerCalibrationsQuery,
  GetAssessmentAnswerCalibrationsQueryVariables,
  JustificationModalCalibrationNodeFragment,
  JustificationModalQuestionNodeFragment,
} from "types/graphql-schema";

import getAssessmentAnswerCalibrationsQuery from "@apps/reporting/graphql/get-assessment-answer-calibrations-query";
import useLabel from "@apps/use-label/use-label";
import Loading from "@components/loading/loading";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

type CalibrationWithQuestion = JustificationModalCalibrationNodeFragment & {
  question?: JustificationModalQuestionNodeFragment;
};

const CalibrationJustificationChangelogModalItem = ({
  calibrationWithQuestion,
}: {
  calibrationWithQuestion: CalibrationWithQuestion;
}) => {
  const label = useLabel();
  const question = calibrationWithQuestion.question
    ? calibrationWithQuestion.question
    : undefined;
  const qLabels =
    question && question.__typename === "RangeAssessmentQuestionNode"
      ? range(question.startValue, question.endValue + 1).reduce(
          (labelObj, value, index) => ({
            ...labelObj,
            [value]: question.labels[index] || value,
          }),
          {} as { [key: number]: string | number }
        )
      : {};

  return (
    <div
      key={calibrationWithQuestion.id}
      className="flex flex-col gap-3 pl-10 relative"
    >
      <div className="absolute left-0 -top-0.5 h-6 w-6 bg-gray-200 rounded-full flex items-center justify-center">
        <PencilIcon className="h-4 w-4 text-gray-600" />
      </div>
      <div className="text-gray-500 flex gap-1 items-center">
        <span className="font-medium">
          <span className="text-gray-700">
            {calibrationWithQuestion.creator?.name || "An admin"}
          </span>{" "}
          edited a {label("review")} response:
        </span>
        <span className="text-gray-400 text-xs font-normal">
          (<TimeAgoCustom date={calibrationWithQuestion.created} />)
        </span>
      </div>
      {calibrationWithQuestion.question &&
        calibrationWithQuestion.question.__typename ===
          "RangeAssessmentQuestionNode" && (
          <div className="flex gap-2">
            <div className="text-gray-500 w-48">Question being changed</div>
            <div className="">{calibrationWithQuestion.question.title}</div>
          </div>
        )}
      {calibrationWithQuestion.__typename ===
        "RangeAssessmentAnswerCalibrationNode" && (
        <div className="flex gap-2">
          <div className="text-gray-500 w-48">Response change</div>
          <div className="flex items-center gap-1">
            {qLabels[calibrationWithQuestion.prevIntegerAnswer || 0]} (
            {calibrationWithQuestion.prevIntegerAnswer})
            <ArrowSmRightIcon className="h-3 w-3 text-gray-400" />
            {qLabels[calibrationWithQuestion.integerAnswer || 0]} (
            {calibrationWithQuestion.integerAnswer})
          </div>
        </div>
      )}
      {calibrationWithQuestion.comment && (
        <div className="flex gap-2">
          <div className="text-gray-500 w-48">Justification</div>
          <TextareaWysiwyg
            value={calibrationWithQuestion.comment}
            editable={false}
            className="w-full -mt-1"
          />
        </div>
      )}
    </div>
  );
};

const CalibrationChangelog = ({
  assessmentId,
  className,
}: {
  assessmentId: number;
  className?: string;
}) => {
  const { data, loading } = useQuery<
    GetAssessmentAnswerCalibrationsQuery,
    GetAssessmentAnswerCalibrationsQueryVariables
  >(getAssessmentAnswerCalibrationsQuery, {
    variables: { assessmentId },
    onError: onNotificationErrorHandler(),
  });

  const calibrationsWithQuestion = useMemo(() => {
    const answers = data?.assessment?.answers
      ? assertEdgesNonNull(data.assessment.answers)
      : [];
    const flattenedCalibrationsWithQuestion = answers.reduce((memo, answer) => {
      const calibrations = assertEdgesNonNull(answer.calibrations).map(
        (calibration) => ({
          ...calibration,
          question:
            answer.question.__typename === "RangeAssessmentQuestionNode"
              ? answer.question
              : undefined,
        })
      );
      return [...memo, ...calibrations];
    }, [] as (JustificationModalCalibrationNodeFragment & { question?: JustificationModalQuestionNodeFragment })[]);
    return sortBy(
      flattenedCalibrationsWithQuestion,
      (calibration) => -moment(calibration.created).unix()
    );
  }, [data]);

  return (
    <div className={classNames("text-sm", className)}>
      {loading ? (
        <Loading />
      ) : calibrationsWithQuestion.length === 0 ? (
        <div>No change log.</div>
      ) : (
        <div className="relative flex flex-col gap-8">
          <div className="absolute left-[11px] top-0 bottom-0 w-[2px] bg-gray-200" />
          {calibrationsWithQuestion.map((calibration) => (
            <CalibrationJustificationChangelogModalItem
              key={calibration.id}
              calibrationWithQuestion={calibration}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CalibrationChangelog;
