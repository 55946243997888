import { ArtifactType, GoalState, GoalStatus } from "types/graphql-schema";
import { DateRangeEnum, PastOnlyDateRangeEnum } from "types/topicflow";

import { currentOrganizationVar } from "@cache/cache";
import { artifactType } from "@helpers/constants";
import { capitalize, lowerCase, pluralize, titleCase } from "@helpers/string";

const defaultLabels = {
  [`${ArtifactType.ActionItem}`]: "action item",
  [`${ArtifactType.Decision}`]: "decision",
  [`${ArtifactType.Rating}`]: "rating",
  [`${ArtifactType.Document}`]: "document",
  [`${ArtifactType.Goal}`]: "goal",
  [`${ArtifactType.AsanaTask}`]: "Asana task",
  [`${ArtifactType.Kpi}`]: "KPI",
  [`${ArtifactType.Recognition}`]: "recognition",
  team: "team",
  organization: "organization",
  "key result": "key result",
  [`${GoalStatus.AtRisk}`]: "at risk",
  [`${GoalStatus.OnTrack}`]: "on track",
  [`${GoalStatus.OffTrack}`]: "off track",
  [`${GoalStatus.None}`]: "none",
  [`${GoalState.Draft}`]: "draft",
  [`${GoalState.Open}`]: "in progress",
  [`${GoalState.Closed}`]: "closed",
  [`${DateRangeEnum.nextMonth}`]: "next month",
  [`${DateRangeEnum.thisMonth}`]: "this month",
  [`${DateRangeEnum.previousMonth}`]: "previous month",
  [`${DateRangeEnum.nextQuarter}`]: "next quarter",
  [`${DateRangeEnum.thisQuarter}`]: "this quarter",
  [`${DateRangeEnum.previousQuarter}`]: "previous quarter",
  [`${PastOnlyDateRangeEnum.previousYear}`]: "previous year",
  oneonone: "1-on-1",
  review: "review",
  expectation: "expectation",
  conversation: "conversation",
  development: "development",
  competency: "competency",
} as { [key: string]: string };

interface LabelTransformers {
  capitalize?: boolean;
  pluralize?: boolean | number;
  titleCase?: boolean;
}

export type UseLabelType = (
  sentence: keyof typeof defaultLabels,
  transformers?: LabelTransformers
) => string;

export const getLabel = (): UseLabelType => {
  const currentOrganization = currentOrganizationVar();

  const labels = {
    ...defaultLabels,
    [`${artifactType.goal}`]: lowerCase(
      currentOrganization?.goalLabel || defaultLabels[`${artifactType.goal}`]
    ),
    "key result": lowerCase(
      currentOrganization?.keyResultLabel || defaultLabels["key result"]
    ),
    team: lowerCase(currentOrganization?.teamLabel || defaultLabels["team"]),
    organization: lowerCase(
      currentOrganization?.orgLabel || defaultLabels["organization"]
    ),
    recognition: lowerCase(
      currentOrganization?.recognitionLabel || defaultLabels["recognition"]
    ),
    oneonone: lowerCase(
      currentOrganization?.oneononeLabel || defaultLabels["oneonone"]
    ),
    review: lowerCase(
      currentOrganization?.reviewLabel || defaultLabels["review"]
    ),
    expectation: lowerCase(
      currentOrganization?.expectationLabel || defaultLabels["expectation"]
    ),
    conversation: lowerCase(
      currentOrganization?.conversationLabel || defaultLabels["conversation"]
    ),
    development: lowerCase(
      currentOrganization?.developmentLabel || defaultLabels["development"]
    ),
    competency: lowerCase(
      currentOrganization?.competencyLabel || defaultLabels["competency"]
    ),
  };

  return function (
    sentence: keyof typeof defaultLabels,
    transformers: LabelTransformers = {}
  ) {
    const defaultTransformers = {
      capitalize: false,
      pluralize: false,
      titleCase: false,
    };

    const label = lowerCase(labels[`${sentence}`] || String(sentence));
    return Object.keys({ ...defaultTransformers, ...transformers }).reduce(
      (memo: string, transformerKey: string) => {
        if (
          transformerKey === "pluralize" &&
          transformers[transformerKey] === true
        ) {
          return pluralize(memo, 2);
        }
        if (
          transformerKey === "pluralize" &&
          typeof transformers[transformerKey] === "number"
        ) {
          return pluralize(memo, transformers[transformerKey] as number);
        }
        if (transformerKey === "titleCase" && transformers[transformerKey]) {
          return titleCase(memo);
        }
        if (transformerKey === "capitalize" && transformers[transformerKey]) {
          return capitalize(memo);
        }
        return memo;
      },
      label
    );
  };
};

const useLabel = (): UseLabelType => getLabel();

export default useLabel;
