import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

const AppLabel = ({
  label,
  tooltip,
  className = "",
}: {
  label: string;
  tooltip?: string;
  className?: string;
}) => {
  const content = (
    <span
      className={classNames(
        "overflow-hidden whitespace-nowrap shrink-0 flex-nowrap text-2xs bg-gray-200 text-gray-500 font-medium rounded px-1 py-px uppercase tracking-tighter hover:no-underline",
        className
      )}
    >
      {label}
    </span>
  );
  return tooltip ? <Tooltip text={tooltip}>{content}</Tooltip> : content;
};

export default AppLabel;
