import { createContext, useContext } from "react";

import useLabel from "@apps/use-label/use-label";

type OverallGoalAssessmentQuestionDescriptionContextType = {
  averageScoreOfIndividualGoalBasedQuestions?: string;
};

export const OverallGoalAssessmentQuestionDescriptionContext =
  createContext<OverallGoalAssessmentQuestionDescriptionContextType>({});

const OverallGoalAssessmentQuestionDescription = () => {
  const context = useContext(OverallGoalAssessmentQuestionDescriptionContext);
  const label = useLabel();

  return context.averageScoreOfIndividualGoalBasedQuestions ? (
    <div className="mt-1 text-sm text-gray-500">
      Average score based on above {label("goal")} assessments:{" "}
      {context.averageScoreOfIndividualGoalBasedQuestions}
      /5
    </div>
  ) : null;
};

export default OverallGoalAssessmentQuestionDescription;
