import { useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { uniqBy } from "lodash";
import { useState } from "react";
import {
  GetOrganizationCareerTrackRolesQuery,
  GetOrganizationCareerTrackRolesQueryVariables,
} from "types/graphql-schema";

import getOrganizationCareerTrackRolesQuery from "@apps/org-settings/graphql/get-organization-career-track-roles-query";
import { currentOrganizationVar } from "@cache/cache";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { useNotificationError } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

export type CareerTrackRolePickerRole = {
  id: number;
  title: string;
  careerTrack: { id: number; title: string };
};

const CareerTrackRolePickerDropdown = ({
  roles,
  onChange,
}: {
  roles: CareerTrackRolePickerRole[];
  onChange: (value: CareerTrackRolePickerRole) => void;
}) => {
  const currentOrganization = currentOrganizationVar();
  const [options, setOptions] = useState<
    ComboboxGenericOption<CareerTrackRolePickerRole>[]
  >([]);
  const roleIds = roles.map(({ id }) => id);
  const [canLoadData, setCanLoadData] = useState(false);
  const { onError } = useNotificationError();

  const { loading } = useQuery<
    GetOrganizationCareerTrackRolesQuery,
    GetOrganizationCareerTrackRolesQueryVariables
  >(getOrganizationCareerTrackRolesQuery, {
    variables: {
      organizationId: currentOrganization.id,
    },
    skip: !canLoadData,
    onError,
    onCompleted: (data) => {
      const roles = assertEdgesNonNull(data.careerRoles);
      setOptions(
        uniqBy(roles, "id").map((role) => ({
          label: role.title,
          value: role,
          description: role.careerTrack.title,
        }))
      );
    },
  });

  const filteredOptions = options.filter(
    (option) => !option.value.id || !roleIds.includes(option.value.id)
  );

  const handleChangeRole = (
    option: ComboboxGenericOption<CareerTrackRolePickerRole>
  ) => {
    onChange(option.value);
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  return (
    <ComboboxGeneric
      aria-label="Career Track picker dropdown"
      loading={loading}
      value={null}
      options={filteredOptions}
      onChangeValue={handleChangeRole}
      onClickButton={handlePreloadData}
    >
      {({ setReferenceElement }) => (
        <div className={"flex justify-between bg-white rounded-md"}>
          <Popover.Button
            className="flex items-center text-sm gap-1 border rounded-lg py-1 px-2"
            onClick={handlePreloadData}
            ref={setReferenceElement}
          >
            <PlusIcon className="h-4 w-4" />
            Pick a Role
          </Popover.Button>
        </div>
      )}
    </ComboboxGeneric>
  );
};

const CareerTrackRolePicker = ({
  roles,
  onRemoveRole,
  onAddRole,
  canChange,
}: {
  roles: CareerTrackRolePickerRole[];
  onRemoveRole: (recipient: CareerTrackRolePickerRole) => void;
  onAddRole: (option: CareerTrackRolePickerRole) => void;
  canChange: boolean;
}) => {
  return (
    <div className="flex-1 flex gap-2 flex-wrap">
      {roles.map((role) => (
        <div
          className="border rounded-lg px-2 py-1 text-sm flex items-center gap-1"
          key={role.id}
        >
          {`${role.title} (${role.careerTrack.title})`}
          {canChange && (
            <button
              className="ml-0.5 rounded-full p-0.5 border bg-gray-50 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
              onClick={() => onRemoveRole(role)}
            >
              <XIcon className="h-3 w-3" />
            </button>
          )}
        </div>
      ))}
      {canChange && (
        <CareerTrackRolePickerDropdown roles={roles} onChange={onAddRole} />
      )}
    </div>
  );
};

export default CareerTrackRolePicker;
