import { compact, noop } from "lodash";
import { AssessmentDeliveryFragment } from "types/graphql-schema";

import Button from "@components/button/button";
import Loading from "@components/loading/loading";

import AssessmentLayout from "../layouts/assessment-layout";
import AssessmentDeliveryContent from "./assessment-delivery-content";

const CompletedAssessmentDeliveryStack = ({
  assessmentDeliveryData,
  isShowingHiddenQuestions,
  title,
  shouldHaveData,
  isSaving,
  isLoading,
  onReopen,
}: {
  assessmentDeliveryData?: AssessmentDeliveryFragment;
  shouldHaveData: boolean;
  isShowingHiddenQuestions: boolean;
  title: string;
  onReopen?: () => void;
  isSaving?: boolean;
  canUpdate?: boolean;
  isLoading?: boolean;
}) => {
  if (!assessmentDeliveryData && !shouldHaveData) {
    return null;
  }
  if (isLoading) {
    return (
      <AssessmentLayout.AssessmentTypeSection>
        <AssessmentLayout.AssessmentTypeHeader>
          {title}
        </AssessmentLayout.AssessmentTypeHeader>
        <div className="mt-4">
          <Loading />
        </div>
      </AssessmentLayout.AssessmentTypeSection>
    );
  }
  if (!assessmentDeliveryData) {
    return (
      <AssessmentLayout.AssessmentTypeSection>
        <AssessmentLayout.AssessmentTypeHeader>
          {title}
        </AssessmentLayout.AssessmentTypeHeader>
        <AssessmentLayout.ResponsesNotAvailable>
          Responses are not available yet. Please check back later.
        </AssessmentLayout.ResponsesNotAvailable>
      </AssessmentLayout.AssessmentTypeSection>
    );
  }

  return (
    <AssessmentLayout.AssessmentTypeSection>
      <div className="flex gap-6 items-center">
        <AssessmentLayout.AssessmentTypeHeader>
          {title}
        </AssessmentLayout.AssessmentTypeHeader>
        {assessmentDeliveryData.canUpdate?.permission &&
          assessmentDeliveryData.deliveryDatetime && (
            <div className="flex items-center gap-2">
              <Button className="mr-2" disabled={isSaving} onClick={onReopen}>
                Reopen delivery package
              </Button>
              {isSaving && <Loading mini size="5" />}
            </div>
          )}
      </div>
      <div className="mt-12">
        <AssessmentDeliveryContent
          assessmentDeliveryData={assessmentDeliveryData}
          canUpdate={false}
          isDraft={false}
          excludedAnswerIds={compact(assessmentDeliveryData.excludedAnswerIds)}
          summary={assessmentDeliveryData.summary}
          onUpdateDeliveryData={noop}
          showHiddenQuestions={isShowingHiddenQuestions}
        />
      </div>
    </AssessmentLayout.AssessmentTypeSection>
  );
};

export default CompletedAssessmentDeliveryStack;
