import { BasicUser } from "types/topicflow";

import Avatar from "@components/avatar/avatar";

import { AssessmentAnswerWithResponderAndId } from "../questions/types";

const AssessmentDeliveryResponderCheckbox = ({
  hiddenFromSubject,
  canUpdate,
  isExcluded,
  answer,
  target,
  excludedAnswerIds,
  onUpdateExcludedAnswers,
}: {
  hiddenFromSubject: boolean;
  canUpdate: boolean;
  isExcluded: boolean;
  answer: AssessmentAnswerWithResponderAndId;
  target?: BasicUser | null;
  excludedAnswerIds: number[];
  onUpdateExcludedAnswers: (excludedAnswerIds: number[]) => void;
}) => {
  return (
    <div className="flex items-center text-sm mb-2">
      {canUpdate && (
        <input
          className="mr-2 disabled:cursor-not-allowed"
          type="checkbox"
          checked={hiddenFromSubject ? false : !isExcluded}
          disabled={hiddenFromSubject}
          onChange={(evt) => {
            const newAnswers = !evt.target.checked
              ? excludedAnswerIds.concat([answer.id])
              : excludedAnswerIds.filter((a) => a !== answer.id);
            onUpdateExcludedAnswers(newAnswers);
          }}
        />
      )}
      <Avatar className="mr-1" user={answer.responder} size="4" />{" "}
      {answer.responder?.name ?? "Anonymous"}
      {answer.responder?.id === target?.id ? " (Self)" : ""}
      {isExcluded ? " [excluded]" : ""}
    </div>
  );
};
export default AssessmentDeliveryResponderCheckbox;
