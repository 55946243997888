import { PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

import RoleBasedAssessmentQuestionScale from "../questions/role-based-assessment-question-scale";

const LARGE_SIDE = "w-1/2 lg:w-2/3";
const SMALL_SIDE = "w-1/2 lg:w-1/3";

const RoleBasedAssessmentQuestionLayout = ({ children }: PropsWithChildren) => {
  return <div className="border rounded-lg">{children}</div>;
};

const Header = ({
  title,
  startValue,
  endValue,
  labels,
  hasResponders,
}: {
  title: string;
  startValue: number;
  endValue: number;
  labels: ReadonlyArray<string | null>;
  hasResponders: boolean;
}) => {
  return (
    <div className="bg-gray-50 rounded-t-lg flex items-center justify-between py-4 px-6">
      <div className={classNames(LARGE_SIDE, "mr-4 font-bold")}>{title}</div>
      <div className={classNames(SMALL_SIDE, "flex gap-6")}>
        {hasResponders && <div className="h-5 w-5"></div>}
        <RoleBasedAssessmentQuestionScale
          startValue={startValue}
          endValue={endValue}
          labels={labels}
        />
      </div>
    </div>
  );
};

const Row = ({
  children,
  showError,
}: PropsWithChildren<{ showError?: boolean }>) => {
  return (
    <div
      className={classNames(
        "flex justify-between py-4 px-6 border-t",
        showError && "bg-red-50"
      )}
    >
      {children}
    </div>
  );
};

const RowContent = ({
  children,
  borderRight,
}: PropsWithChildren<{ borderRight?: boolean }>) => {
  return (
    <div
      className={classNames(
        LARGE_SIDE,
        borderRight && "border-r",
        "pr-4 mr-4 flex flex-col gap-6"
      )}
    >
      {children}
    </div>
  );
};

const RowChoices = ({ children }: PropsWithChildren) => {
  return (
    <div className={classNames(SMALL_SIDE, "flex flex-col gap-2 -mt-1.5")}>
      {children}
    </div>
  );
};

const Choices = ({ children }: PropsWithChildren) => {
  return <div className="flex items-center gap-6">{children}</div>;
};

const Container = ({ children }: PropsWithChildren) => {
  return <div className="mt-12">{children}</div>;
};

RoleBasedAssessmentQuestionLayout.Header = Header;
RoleBasedAssessmentQuestionLayout.Row = Row;
RoleBasedAssessmentQuestionLayout.RowContent = RowContent;
RoleBasedAssessmentQuestionLayout.RowChoices = RowChoices;
RoleBasedAssessmentQuestionLayout.Choices = Choices;
RoleBasedAssessmentQuestionLayout.Container = Container;

export default RoleBasedAssessmentQuestionLayout;
