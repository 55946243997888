import { isInteger, map } from "lodash";
import { useCallback, useState } from "react";

import useLabel from "@apps/use-label/use-label";
import ToggleSwitch from "@components/toggle-switch/toggle-switch";
import { assertNonNull } from "@helpers/helpers";

import RoleBasedAssessmentQuestionLayout from "../layouts/role-based-assessment-question-layout";
import {
  getQuestionsByRole,
  isRoleBasedQuestionType,
} from "../questions/helpers";
import RoleBasedAssessmentQuestionGroup from "../questions/role-based-assessment-question-group";
import {
  AssessmentAnswer,
  AssessmentQuestionContainer,
} from "../questions/types";

const AssessmentRoleBasedSection = ({
  sectionQuestions,
  targetCurrentRoleIds,
  onUpdateAnswer,
  onDeleteAnswers,
  formDisabled,
  showErrors,
  allAnswers,
  nextRoleAssessmentEnabled,
  isSelfAssessment,
}: {
  sectionQuestions: AssessmentQuestionContainer[];
  targetCurrentRoleIds: number[];
  onUpdateAnswer: (answer: AssessmentAnswer) => void;
  onDeleteAnswers: (questionIds: number[]) => void;
  formDisabled: boolean;
  showErrors: boolean;
  nextRoleAssessmentEnabled: boolean;
  allAnswers: AssessmentAnswer[];
  isSelfAssessment: boolean;
}) => {
  const label = useLabel();
  const roleBasedQuestions = sectionQuestions.filter(({ question }) =>
    isRoleBasedQuestionType(question.questionType)
  );

  const [isShowingNextRoleQuestions, setIsShowingNextRoleQuestions] = useState(
    // auto open the next role questions section if there are already answers
    roleBasedQuestions.some(({ question }) => {
      const isQuestionAnswered = allAnswers.some(
        (answer) =>
          answer.questionId === question.id && isInteger(answer.integerAnswer)
      );

      if (
        question.__typename === "CompetencyAssessmentQuestionNode" ||
        question.__typename === "CompetencyCriteriaAssessmentQuestionNode"
      ) {
        return (
          !targetCurrentRoleIds.includes(question.role.id) && isQuestionAnswered
        );
      } else if (
        question.__typename === "ResponsibilityAssessmentQuestionNode"
      ) {
        return (
          question.responsibility &&
          !targetCurrentRoleIds.includes(question.responsibility.role.id) &&
          isQuestionAnswered
        );
      }

      return false;
    })
  );

  const {
    roles: currentRoles,
    roleBasedQuestionsByRoleId: currentRoleQuestionsByRoleId,
  } = getQuestionsByRole(roleBasedQuestions, (role) => {
    return targetCurrentRoleIds.includes(role.id);
  });
  const {
    roles: nextRoles,
    roleBasedQuestionsByRoleId: nextRoleQuestionsByRoleId,
  } = getQuestionsByRole(roleBasedQuestions, (role) => {
    return !targetCurrentRoleIds.includes(role.id);
  });

  const handleToggleNextRoleQuestions = useCallback(
    (checked: boolean) => {
      setIsShowingNextRoleQuestions(checked);
      if (!checked) {
        onDeleteAnswers(
          roleBasedQuestions
            .filter(({ question }) => {
              if (
                question.__typename === "CompetencyAssessmentQuestionNode" ||
                question.__typename ===
                  "CompetencyCriteriaAssessmentQuestionNode"
              ) {
                return !targetCurrentRoleIds.includes(question.role.id);
              } else if (
                question.__typename === "ResponsibilityAssessmentQuestionNode"
              ) {
                return (
                  question.responsibility &&
                  !targetCurrentRoleIds.includes(
                    question.responsibility.role.id
                  )
                );
              }

              return false;
            })
            .map(({ question }) => question.id)
        );
      }
    },
    [onDeleteAnswers, roleBasedQuestions, targetCurrentRoleIds]
  );

  if (roleBasedQuestions.length === 0) {
    return null;
  }

  return (
    <>
      {map(currentRoleQuestionsByRoleId, (questions, roleIdStr) => {
        const role = assertNonNull(
          currentRoles.find((role) => role.id === parseInt(roleIdStr))
        );

        return (
          <RoleBasedAssessmentQuestionLayout.Container key={roleIdStr}>
            <RoleBasedAssessmentQuestionGroup
              allAnswers={allAnswers}
              role={role}
              questions={questions}
              onUpdateAnswer={onUpdateAnswer}
              formDisabled={formDisabled}
              showError={showErrors}
              isCurrentRole
            />
          </RoleBasedAssessmentQuestionLayout.Container>
        );
      })}

      {nextRoleAssessmentEnabled && !formDisabled && (
        <div className="mt-12 p-4 rounded-lg bg-blue-100 flex items-center justify-between">
          <div>
            <div className="font-semibold">{`Next Level ${label("review", {
              capitalize: true,
              pluralize: true,
            })}`}</div>
            <div className="text-xs">
              {isSelfAssessment
                ? nextRoles.length === 0
                  ? "This section is not available. You have no next role"
                  : `Enable this setting to ${label("review")} your next role`
                : nextRoles.length === 0
                ? "This section is not available for this employee. There is no next role setup for them."
                : `Enable this setting to ${label(
                    "review"
                  )} this employee in their next role`}
            </div>
          </div>
          <ToggleSwitch
            disabled={nextRoles.length === 0}
            checked={isShowingNextRoleQuestions}
            onChange={handleToggleNextRoleQuestions}
            tooltip={
              nextRoles.length === 0
                ? "There is no next role available"
                : undefined
            }
          />
        </div>
      )}
      {nextRoles.length > 0 &&
        isShowingNextRoleQuestions &&
        map(nextRoleQuestionsByRoleId, (questions, roleIdStr) => {
          const role = assertNonNull(
            nextRoles.find((role) => role.id === parseInt(roleIdStr))
          );

          return (
            <RoleBasedAssessmentQuestionLayout.Container key={roleIdStr}>
              <RoleBasedAssessmentQuestionGroup
                allAnswers={allAnswers}
                role={role}
                questions={questions}
                onUpdateAnswer={onUpdateAnswer}
                formDisabled={formDisabled}
                showError={showErrors}
                isCurrentRole={false}
              />
            </RoleBasedAssessmentQuestionLayout.Container>
          );
        })}
    </>
  );
};

export default AssessmentRoleBasedSection;
