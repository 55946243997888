import { gql } from "@apollo/client";

export default gql`
  fragment JustificationModalCalibrationNode on RangeAssessmentAnswerCalibrationNode {
    id
    comment
    creator {
      id
      name
      avatar
    }
    created
    ... on RangeAssessmentAnswerCalibrationNode {
      integerAnswer
      prevIntegerAnswer
    }
  }
  fragment JustificationModalQuestionNode on RangeAssessmentQuestionNode {
    id
    title
    ... on RangeAssessmentQuestionNode {
      labels
      startValue
      endValue
    }
  }
  query getAssessmentAnswerCalibrations($assessmentId: Int!) {
    assessment(assessmentId: $assessmentId) {
      id
      answers {
        edges {
          node {
            id
            question {
              ...JustificationModalQuestionNode
            }
            calibrations {
              edges {
                node {
                  ...JustificationModalCalibrationNode
                }
              }
            }
          }
        }
      }
    }
  }
`;
