import { useMutation } from "@apollo/client";
import { PencilIcon } from "@heroicons/react/outline";
import * as Sentry from "@sentry/react";
import { generateHTML } from "@tiptap/core";
import Placeholder from "@tiptap/extension-placeholder";
import { range } from "lodash";
import { useState } from "react";
import { MdOutlineChat } from "react-icons/md";
import {
  AssessmentQuestionType,
  ComplianceProgramReportAnswerFragment,
  UpdateAssessmentAnswerMutation,
  UpdateAssessmentAnswerMutationVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import RangeAssessmentQuestionContent from "@apps/assessments/components/questions/range-assessment-question-content";
import updateAssessmentAnswerMutation from "@apps/reporting/graphql/update-assessment-answer-mutation";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { TableBodyCell } from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import TipTapCustomImage from "@components/wysiwyg/extensions/image";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg, {
  TextareaWysiwygExtensions,
} from "@components/wysiwyg/textarea-wysiwyg";
import { assertNonNull, parseStringToJSON } from "@helpers/helpers";

import { PerformanceAssessmentTableQuestion } from "./performance-assessment-table";

const PerformanceAssessmentTableRowAnswerCell = ({
  answers,
  question,
  canAdminCalibrate,
  responder,
}: {
  answers: ComplianceProgramReportAnswerFragment[];
  question: PerformanceAssessmentTableQuestion;
  canAdminCalibrate: boolean;
  responder: BasicUser;
}) => {
  const answer = answers.find((a) => a.questionId === question.id);
  const [isCalibrationModalOpen, setIsCalibrationModalOpen] = useState(false);

  const [justification, setJustification] = useState("");

  const [updateAssessmentAnswer, { loading: isSavingAssessment }] = useMutation<
    UpdateAssessmentAnswerMutation,
    UpdateAssessmentAnswerMutationVariables
  >(updateAssessmentAnswerMutation);

  const qLabels = range(question.startValue, question.endValue + 1).reduce(
    (labelObj, value, index) => ({
      ...labelObj,
      [value]: question.labels[index] || value,
    }),
    {} as { [key: number]: string | number }
  );
  const emptyAnswer: {
    question: PerformanceAssessmentTableQuestion;
    integerAnswer: number | undefined | null;
    comment: string | null | undefined;
  } = { question: question, integerAnswer: undefined, comment: null };
  const [localAnswer, setLocalAnswer] = useState(
    answer && answer.__typename === "RangeAssessmentAnswerNode"
      ? answer
      : emptyAnswer
  );

  if (question.questionType === AssessmentQuestionType.Text) {
    return null;
  }
  if (!answer || answer.__typename !== "RangeAssessmentAnswerNode") {
    return <TableBodyCell key={question.id}>N/A</TableBodyCell>;
  }

  const handleClickSave = () => {
    if (
      localAnswer.integerAnswer !== undefined &&
      localAnswer.integerAnswer !== null
    ) {
      updateAssessmentAnswer({
        variables: {
          assessmentAnswerId: answer.id,
          integerAnswer: localAnswer.integerAnswer,
          comment: justification,
        },
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          handleCloseModal();
        },
      });
    }
  };

  const handleCloseModal = () => {
    setJustification("");
    setIsCalibrationModalOpen(false);
  };

  let commentHtml = null;
  if (!isEmptyValue(answer.comment)) {
    try {
      commentHtml = generateHTML(parseStringToJSON(answer.comment), [
        ...TextareaWysiwygExtensions,
        Placeholder.configure({
          placeholder: "",
        }),
        TipTapCustomImage(undefined),
      ]);
    } catch (e) {
      if (import.meta.env.DEV) {
        throw new Error("Performance assessment comment rendering problem");
      } else {
        Sentry.captureException(
          "Performance assessment comment rendering problem",
          { extra: { error: e } }
        );
      }
    }
  }
  const integerAnswer = assertNonNull(answer.integerAnswer);
  return (
    <TableBodyCell key={question.id}>
      <div className="flex items-center gap-2">
        {qLabels[integerAnswer]}
        <div className="flex items-center gap-1 empty:hidden">
          {commentHtml !== null && (
            <Button
              icon={MdOutlineChat}
              size={ButtonSize.small}
              theme={buttonTheme.iconGray}
              tooltip={commentHtml}
            />
          )}
          {canAdminCalibrate && (
            <Button
              icon={PencilIcon}
              size={ButtonSize.small}
              theme={buttonTheme.iconGray}
              onClick={() => setIsCalibrationModalOpen(true)}
            />
          )}
        </div>
      </div>
      {isCalibrationModalOpen && (
        <Modal onClose={handleCloseModal}>
          <div className="flex flex-col divide-y">
            <div className="p-6">
              <ModalTitle onClose={handleCloseModal}>
                Edit Response from {responder.name}
              </ModalTitle>
              <div className="mt-4">
                <span className="text-gray-500">Question: </span>
                {question.title}
              </div>
            </div>
            <div className="p-6">
              <div className="font-medium">{responder.name}'s answer</div>
              <div className="mt-2 grayscale">
                <RangeAssessmentQuestionContent
                  disabled={true}
                  answer={answer}
                  question={question}
                />
                {isEmptyValue(answer.comment) && (
                  <div className="mt-2 text-sm text-gray-400">No comment.</div>
                )}
              </div>
            </div>
            <div className="p-6">
              <div className="font-medium">New answer</div>
              <div className="mt-2">
                <RangeAssessmentQuestionContent
                  disabled={false}
                  answer={{ ...localAnswer, comment: undefined }}
                  question={question}
                  forceHideComment={true}
                  onChangeAnswer={(newAnswer) => {
                    setLocalAnswer({
                      ...localAnswer,
                      integerAnswer:
                        newAnswer.integerAnswer !== undefined
                          ? newAnswer.integerAnswer
                          : localAnswer.integerAnswer,
                    });
                  }}
                />
              </div>
            </div>
            <div className="p-6">
              <div className="font-medium mb-2">
                Add a comment for justification
              </div>
              <TextareaWysiwyg
                editable
                value={justification}
                onChangeValue={(content) => setJustification(content)}
                placeholder="Explain why you're making a change"
              />
            </div>
            <div className="flex items-center justify-end gap-4 p-6">
              {isSavingAssessment && <Loading mini size={5} />}
              <Button
                text="Cancel"
                size={ButtonSize.large}
                onClick={handleCloseModal}
              />
              <Button
                disabled={
                  isSavingAssessment ||
                  localAnswer.integerAnswer === answer?.integerAnswer
                }
                tooltip={
                  localAnswer.integerAnswer === answer?.integerAnswer
                    ? "Select a different answer to make a change"
                    : undefined
                }
                text={`Submit`}
                theme={buttonTheme.primary}
                size={ButtonSize.large}
                onClick={handleClickSave}
              />
            </div>
          </div>
        </Modal>
      )}
    </TableBodyCell>
  );
};

export default PerformanceAssessmentTableRowAnswerCell;
