import { useReactiveVar } from "@apollo/client";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { PropsWithChildren, RefObject, useCallback } from "react";

import { isConfirmationOpenVar } from "@cache/cache";
import { classNames } from "@helpers/css";

const Sidebar = ({
  children,
  show,
  title,
  className = "",
  focusRef,
  onClose,
}: PropsWithChildren<{
  show: boolean;
  title?: string;
  className?: string;
  focusRef?: RefObject<HTMLElement>;
  onClose: () => void;
}>) => {
  const isConfirmationOpen = useReactiveVar(isConfirmationOpenVar);

  const handleClose = useCallback(() => {
    // if the confirmation modal is open, we don't want to close the sidebar
    if (!isConfirmationOpen) {
      onClose();
    }
  }, [isConfirmationOpen, onClose]);

  return (
    <Dialog
      as="div"
      open={show}
      className="fixed inset-0 overflow-hidden z-rightSidebar"
      onClose={handleClose}
      initialFocus={focusRef}
    >
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-black opacity-20" />

        <div className="fixed inset-y-0 right-0 max-w-full flex">
          <div className="w-screen max-w-2xl">
            {!!children && (
              <div
                className={classNames(
                  "h-full flex flex-col bg-white shadow-xl overflow-y-scroll",
                  className
                )}
              >
                {title && (
                  <div className="px-4 sm:px-6 mt-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          onClick={handleClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="relative flex-1">
                  <div className="absolute inset-0">
                    <div className="h-full">
                      {/* pb-12 is to leave space for the Intercom button so there are no overlaps */}
                      <div className="pb-12 pt-6">{children}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Sidebar;
