import { compact } from "lodash";
import { PropsWithChildren, useMemo } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import Assessment from "@apps/assessments/components/assessment/assessment";
import useLabel from "@apps/use-label/use-label";
import { isAdminVar } from "@cache/cache";
import { buttonTheme } from "@components/button/button";
import Button from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { classNames } from "@helpers/css";

import AssessmentGroups from "./components/assessment-groups";
import AssessmentQuestions from "./components/assessment-questions";
import AssessmentDelivery from "./components/delivery/assessment-delivery";
import AssessmentGroupEdit from "./components/edit-assessment-group";
import AssessmentQuestionEdit from "./components/edit-assessment-question";
import AssessmentsHomepage from "./components/homepage";
import MyAssessments from "./components/my-assessments";

const Nav = () => {
  const label = useLabel();
  const { path } = useRouteMatch();
  const tabs = useMemo(
    () =>
      compact([
        !waffle.flag_is_active("new-review-homepage") && {
          name: label("review", { capitalize: true, pluralize: true }),
          id: "home",
        },
        {
          name: `${label("review", {
            capitalize: true,
          })} templates`,
          id: "assessment-templates",
        },
        {
          name: `${label("review", {
            capitalize: true,
          })} questions`,
          id: "assessment-questions",
        },
      ]),
    [label]
  );

  const activeTab = tabs.find((tab) => path.includes(tab.id));
  const displayedTabId = activeTab ? activeTab.id : tabs[0].id;
  return (
    <div
      className={classNames(
        waffle.flag_is_active("new-review-homepage") ? "" : "mb-6"
      )}
    >
      <nav className="-mb-px flex gap-6" aria-label="Tabs">
        {tabs.map((tab) => (
          <Link
            key={tab.name}
            to={`/assessments/${tab.id}`}
            className={classNames(
              tab.id === displayedTabId
                ? "border-blue-700 text-gray-700"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "whitespace-nowrap py-2 px-1 border-b-3 font-medium text-base"
            )}
            aria-current={tab.id === displayedTabId ? "page" : undefined}
          >
            {tab.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

const OldAssessmentsNavContainer = (
  props: PropsWithChildren & { showNav?: boolean }
) => {
  const label = useLabel();
  const isAdmin = isAdminVar();
  return (
    <div
      aria-label={label("review", { capitalize: true, pluralize: true })}
      className="flex flex-col flex-1 fs-unmask"
    >
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-2xl font-semibold mr-8 flex items-center"
          to="/assessments"
        >
          {label("review", { capitalize: true, pluralize: true })}
        </AppLink>
      </div>
      <div className="p-6">
        {isAdmin && props.showNav && (
          <div className="mb-6">
            <Nav />
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};

const NewAssessmentsNavContainer = (
  props: PropsWithChildren & { showNav?: boolean }
) => {
  const label = useLabel();
  const isAdmin = isAdminVar();
  return (
    <Layout>
      <Layout.Header
        breadcrumbs={[
          {
            title: label("review", { capitalize: true, pluralize: true }),
            url: "/assessments",
          },
        ]}
      >
        {isAdmin && (
          <div className="flex justify-end gap-4 items-center">
            {isAdmin && (
              <>
                <Button
                  text={`${label("review", { capitalize: true })} templates`}
                  theme={buttonTheme.default}
                  to="/assessments/assessment-templates"
                />
                <Button
                  text={`${label("review", { capitalize: true })} questions`}
                  theme={buttonTheme.default}
                  to="/assessments/assessment-questions"
                />
              </>
            )}
            <Button
              text={`Create ${label("review")} program`}
              to="/programs/new"
              theme={buttonTheme.primary}
            />
          </div>
        )}
      </Layout.Header>
      <Layout.Container>
        {props.showNav ? (
          <Layout.Main fullWidth>
            <Layout.MainSection>{props.children}</Layout.MainSection>
          </Layout.Main>
        ) : (
          props.children
        )}
      </Layout.Container>
    </Layout>
  );
};

const AssessmentsNavContainer = (
  props: PropsWithChildren & { showNav?: boolean }
) => {
  return waffle.flag_is_active("new-review-homepage") ? (
    <NewAssessmentsNavContainer {...props} />
  ) : (
    <OldAssessmentsNavContainer {...props} />
  );
};

const Assessments = () => {
  const label = useLabel();
  useDocumentTitle(label("review", { capitalize: true, pluralize: true }));
  const { path } = useRouteMatch();
  const isAdmin = isAdminVar();

  return (
    <Switch>
      <Route
        exact
        path={[
          `${path}/home`,
          `${path}/active`,
          `${path}/delivered`,
          `${path}`,
        ]}
      >
        {waffle.flag_is_active("new-review-homepage") ? (
          <AssessmentsNavContainer>
            <AssessmentsHomepage />
          </AssessmentsNavContainer>
        ) : (
          <AssessmentsNavContainer showNav>
            <MyAssessments />
          </AssessmentsNavContainer>
        )}
      </Route>

      <Route exact path={`${path}/assessment/:assessmentId(\\d+)`}>
        <Assessment />
      </Route>
      <Route
        exact
        path={`${path}/assessment/delivery/:assessmentDeliveryId(\\d+)`}
      >
        <AssessmentDelivery />
      </Route>
      {isAdmin && (
        <>
          <Route exact path={`${path}/assessment-templates`}>
            <AssessmentsNavContainer showNav>
              <AssessmentGroups />
            </AssessmentsNavContainer>
          </Route>
          <Route
            exact
            path={[
              `${path}/assessment-templates/new`,
              `${path}/assessment-templates/:assessmentGroupId(\\d+)/edit`,
              `${path}/assessment-templates/:assessmentGroupId(\\d+)/duplicate`,
            ]}
          >
            <AssessmentsNavContainer showNav>
              <AssessmentGroupEdit />
            </AssessmentsNavContainer>
          </Route>
          <Route exact path={`${path}/assessment-questions`}>
            <AssessmentsNavContainer showNav>
              <AssessmentQuestions />
            </AssessmentsNavContainer>
          </Route>
          <Route
            exact
            path={[
              `${path}/assessment-questions/new`,
              `${path}/assessment-questions/:questionId(\\d+)/edit`,
              `${path}/assessment-questions/:questionId(\\d+)/duplicate`,
            ]}
          >
            <AssessmentsNavContainer showNav>
              <AssessmentQuestionEdit />
            </AssessmentsNavContainer>
          </Route>
        </>
      )}
    </Switch>
  );
};

export default Assessments;
