import { gql } from "@apollo/client";

export default gql`
  fragment ComplianceProgramPopoverContentAssessment on AssessmentNode {
    id
    submittedDatetime
    created
    canRead {
      permission
    }
    template {
      id
      assessmentType
    }
    responder {
      id
      name
    }
    target {
      id
      name
    }
  }
  fragment ComplianceProgramPopoverContentMeeting on MeetingNode {
    id
    title
    meetingGroupId
    isFinalized
    finalizedAt
    startDatetime
    meetingGroup {
      id
      topicTemplate {
        id
      }
    }
    canRead {
      permission
    }
    participants {
      edges {
        node {
          id
          status
          user {
            id
            name
            avatar
            email
          }
        }
      }
    }
  }
  fragment ComplianceProgramPopoverContentAssessmentDelivery on AssessmentDeliveryNode {
    id
    template {
      id
      assessmentType
    }
    target {
      id
      name
    }
  }
  query getComplianceProgramPopoverContent(
    $complianceProgramId: Int!
    $assessmentTargetId: Int
    $assessmentResponderId: Int
    $oneononeTemplateId: Int
    $oneononeParticipants: [Int]
    $fetchAssessments: Boolean!
    $fetchOneonones: Boolean!
    $fetchAssessmentDeliveries: Boolean!
    $oneononeStartDatetimeGte: DateTime
  ) {
    complianceProgram(pk: $complianceProgramId) {
      id
      assessments(
        targetId: $assessmentTargetId
        responderId: $assessmentResponderId
      ) @include(if: $fetchAssessments) {
        edges {
          node {
            ...ComplianceProgramPopoverContentAssessment
          }
        }
      }
      matchingOneonones(
        participants: $oneononeParticipants
        startDatetime_Gte: $oneononeStartDatetimeGte
        templateId: $oneononeTemplateId
      ) @include(if: $fetchOneonones) {
        edges {
          node {
            ...ComplianceProgramPopoverContentMeeting
          }
        }
      }
      assessmentDeliveries(
        targetId: $assessmentTargetId
        state: awaiting_admin_approval
      ) @include(if: $fetchAssessmentDeliveries) {
        edges {
          node {
            ...ComplianceProgramPopoverContentAssessmentDelivery
          }
        }
      }
    }
  }
`;
