import { Popover } from "@headlessui/react";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { range } from "lodash";

import { currentOrganizationVar } from "@cache/cache";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";

const CareerTrackLevelDropdown = ({
  levels,
  onChange,
}: {
  levels: number[];
  onChange: (value: number) => void;
}) => {
  const currentOrganization = currentOrganizationVar();
  const options: ComboboxGenericOption<number>[] = range(
    1,
    currentOrganization.maxCareerTrackRoleLevel + 1
  ).map((level) => ({
    value: level,
    label: `Level ${level}`,
  }));

  const filteredOptions = options.filter(
    (option) =>
      !option.value || !levels.map((level) => level).includes(option.value)
  );

  const handleChange = (option: ComboboxGenericOption<number>) => {
    onChange(option.value);
  };

  return (
    <ComboboxGeneric
      aria-label="Career level dropdown"
      value={null}
      options={filteredOptions}
      onChangeValue={handleChange}
    >
      {({ setReferenceElement }) => (
        <div className={"flex justify-between bg-white rounded-md"}>
          <Popover.Button
            className="flex items-center text-sm gap-1 border rounded-lg py-1 px-2"
            ref={setReferenceElement}
          >
            <PlusIcon className="h-4 w-4" />
            Pick a level
          </Popover.Button>
        </div>
      )}
    </ComboboxGeneric>
  );
};

const CareerLevelPicker = ({
  levels,
  onRemoveLevel,
  onAddLevel,
  canChange,
}: {
  levels: number[];
  onRemoveLevel: (recipient: number) => void;
  onAddLevel: (option: number) => void;
  canChange: boolean;
}) => {
  return (
    <div className="flex-1 flex gap-2 flex-wrap">
      {levels.map((level) => (
        <div
          className="border rounded-lg px-2 py-1 text-sm flex items-center gap-1"
          key={level}
        >
          {`Level ${level}`}
          {canChange && (
            <button
              className="ml-0.5 rounded-full p-0.5 border bg-gray-50 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
              onClick={() => onRemoveLevel(level)}
            >
              <XIcon className="h-3 w-3" />
            </button>
          )}
        </div>
      ))}
      {canChange && (
        <CareerTrackLevelDropdown levels={levels} onChange={onAddLevel} />
      )}
    </div>
  );
};

export default CareerLevelPicker;
