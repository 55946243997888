import { useMutation } from "@apollo/client";
import { ArrowRightIcon, LockClosedIcon } from "@heroicons/react/outline";
import { sortBy } from "lodash";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { MdGridView } from "react-icons/md";
import { useLocation } from "react-router-dom";
import {
  AssessmentGroupAnonymity,
  AssessmentState,
  AssessmentType,
  GetAssessmentQuery,
  UnsubmitAssessmentMutation,
  UnsubmitAssessmentMutationVariables,
} from "types/graphql-schema";

import getAssessmentQuery from "@apps/assessments/graphql/get-assessment-query";
import reopenAssessmentMutation from "@apps/assessments/graphql/reopen-assessment-mutation";
import useLabel from "@apps/use-label/use-label";
import {
  currentUserVar,
  isAdminVar,
  successNotificationVar,
} from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

const AssessmentHeader = ({
  myAssessmentsData,
}: {
  myAssessmentsData?: GetAssessmentQuery;
}) => {
  const location = useLocation();
  const currentUser = currentUserVar();
  const isAdmin = isAdminVar();
  const label = useLabel();

  const assessment = useMemo(
    () => (myAssessmentsData ? myAssessmentsData.assessment : null),
    [myAssessmentsData]
  );
  const complianceProgram = useMemo(
    () => (assessment ? assertNonNull(assessment.complianceProgram) : null),
    [assessment]
  );
  const canReadProgramDetails = useMemo(
    () => complianceProgram?.canReadDetails.permission,
    [complianceProgram]
  );

  const target = useMemo(
    () => (assessment ? assertNonNull(assessment.target) : null),
    [assessment]
  );
  const responder = useMemo(() => assessment?.responder ?? null, [assessment]);
  const isSelfAssessment = useMemo(
    () => target?.id === responder?.id,
    [responder, target]
  );

  const relatedAssessments = useMemo(() => {
    const assessments = myAssessmentsData?.assessment?.relatedAssessments
      ? assertEdgesNonNull(myAssessmentsData.assessment.relatedAssessments)
      : [];
    return sortBy(assessments, (a) =>
      a.template.assessmentType === AssessmentType.Performance
        ? 1
        : a.template.assessmentType === AssessmentType.Manager
        ? 2
        : 3
    );
  }, [myAssessmentsData]);

  const [reopenAssessment, { loading: isReopeningAssessment }] = useMutation<
    UnsubmitAssessmentMutation,
    UnsubmitAssessmentMutationVariables
  >(reopenAssessmentMutation);

  const handleReopenAssessment = useCallback(() => {
    if (!assessment) {
      return;
    }
    reopenAssessment({
      variables: {
        assessmentId: assessment.id,
      },
      refetchQueries: [getAssessmentQuery],
      onCompleted: () => {
        successNotificationVar({
          title: `${label("review", {
            capitalize: true,
          })} reopened. The responder can now edit their responses.`,
        });
      },
      onError: onNotificationErrorHandler(),
    });
  }, [assessment, reopenAssessment, label]);

  if (!assessment) {
    return null;
  }

  const leftCellClassName = "py-2 font-medium w-[200px]";
  const rightCellClassName = "py-2";

  return (
    <div className="m-6 mb-12 flex flex-col text-sm gap-6 pb-6 border-b">
      <div className="flex items-center gap-2">
        <Avatar user={target} size={8} />
        <h2 className="text-2xl font-bold text-gray-800">{`${
          target?.name
        }: ${label("review", { capitalize: true })}`}</h2>
      </div>

      <div>
        <Layout.MainSubSection
          title={`${label("review", { capitalize: true })} Details`}
          collapsible
          defaultIsExpanded
        >
          <table className="text-gray-600 w-full">
            <tr>
              <td className={leftCellClassName}>Program</td>
              {canReadProgramDetails ? (
                <td className={rightCellClassName}>
                  <Button
                    to={`/programs/${complianceProgram?.id}`}
                    theme={buttonTheme.text}
                    leftNegativeMargin
                  >
                    <MdGridView className="shrink-0 w-4 h-4" />
                    {complianceProgram?.title}
                  </Button>
                </td>
              ) : (
                <td
                  className={classNames(
                    rightCellClassName,
                    "flex items-center gap-1"
                  )}
                >
                  <MdGridView className="shrink-0 w-4 h-4" />
                  {complianceProgram?.title}
                </td>
              )}
            </tr>
            <tr>
              <td className={leftCellClassName}>{`${label("review", {
                capitalize: true,
              })} type`}</td>
              <td className={rightCellClassName}>
                {getAssessmentTypeLabel(
                  assertNonNull(assessment.template.assessmentType),
                  isSelfAssessment
                )}
              </td>
            </tr>

            {isSelfAssessment && (
              <tr>
                <td className={leftCellClassName}>Subject</td>
                <td className={rightCellClassName}>
                  <div className="flex items-center">
                    <Avatar
                      className="mr-1"
                      user={assertNonNull(target)}
                      size="5"
                    />{" "}
                    {`${target?.name} (Self ${label("review")})`}
                  </div>
                </td>
              </tr>
            )}
            {!isSelfAssessment && (
              <>
                <tr>
                  <td className={leftCellClassName}>Responder</td>
                  <td className={rightCellClassName}>
                    <div className="flex items-center">
                      <Avatar className="mr-1" user={responder} size="5" />{" "}
                      {responder?.name ?? "Anonymous"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={leftCellClassName}>Subject</td>
                  <td className={rightCellClassName}>
                    <div className="flex items-center">
                      <Avatar
                        className="mr-1"
                        user={assertNonNull(target)}
                        size="5"
                      />{" "}
                      {target?.name}
                    </div>
                  </td>
                </tr>
                {target?.organizationSpecificInfo?.position && (
                  <tr>
                    <td className={leftCellClassName}>Position</td>
                    <td className={rightCellClassName}>
                      {target?.organizationSpecificInfo?.position}
                    </td>
                  </tr>
                )}
                {target?.organizationSpecificInfo?.jobLevel && (
                  <tr>
                    <td className={leftCellClassName}>Job level</td>
                    <td className={rightCellClassName}>
                      {target?.organizationSpecificInfo?.jobLevel}
                    </td>
                  </tr>
                )}
              </>
            )}

            <tr>
              <td className={leftCellClassName}>Program period</td>
              <td className={rightCellClassName}>
                {assessment?.periodStartDate && assessment?.periodEndDate
                  ? `${moment(assessment.periodStartDate).format(
                      "MMM D, YYYY"
                    )} - ${moment(assessment.periodEndDate).format(
                      "MMM D, YYYY"
                    )}`
                  : "Not set"}
              </td>
            </tr>
            <tr>
              <td className={leftCellClassName}>Due date</td>
              <td className={rightCellClassName}>
                {moment(assessment?.dueDate).format("MMM D, YYYY")}
              </td>
            </tr>
            {assessment.state === AssessmentState.Submitted && (
              <tr>
                <td className={leftCellClassName}>Submitted</td>
                <td className={rightCellClassName}>
                  <div className="text-green-700">
                    {moment(assessment.submittedDatetime).format(
                      "MMM D, YYYY @ h:mma"
                    )}
                  </div>
                </td>
              </tr>
            )}
          </table>
        </Layout.MainSubSection>

        {relatedAssessments.length > 0 && (
          <Layout.MainSubSection
            className="mt-6"
            title={`Related ${label("review", {
              capitalize: true,
              pluralize: true,
            })}`}
            collapsible
            defaultIsExpanded
          >
            <table className="w-full">
              {relatedAssessments.map((relatedAssessment) => {
                return (
                  <tr key={relatedAssessment.id}>
                    <td className="text-gray-600 w-[200px]">
                      {`${getAssessmentTypeLabel(
                        assertNonNull(
                          relatedAssessment.template.assessmentType
                        ),
                        relatedAssessment.responder?.id ===
                          relatedAssessment.target?.id
                      )} ${label("review")}`}
                    </td>
                    <td>
                      <div className="flex items-center gap-1">
                        <Avatar
                          user={assertNonNull(relatedAssessment.responder)}
                          size="5"
                        />
                        <div>{relatedAssessment.responder?.name}</div>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center justify-end gap-2">
                        <Button
                          theme={buttonTheme.text}
                          to={{
                            pathname: `/assessments/assessment/${relatedAssessment.id}`,
                            state: {
                              previousPathname: `${location.pathname}${location.search}`,
                            },
                          }}
                        >
                          <div className="text-green-700">{`Submitted ${moment(
                            relatedAssessment.submittedDatetime
                          ).format("MMM D")}`}</div>
                          <ArrowRightIcon className="h-4 w-4" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </Layout.MainSubSection>
        )}
      </div>

      {assessment.state === AssessmentState.Submitted && isAdmin && (
        <div className="flex items-center w-full gap-2">
          <Button
            className="mr-2"
            onClick={handleReopenAssessment}
            disabled={isReopeningAssessment}
          >
            Reopen
          </Button>
          {isReopeningAssessment && <Loading size="5" mini />}
        </div>
      )}

      {[
        AssessmentGroupAnonymity.SemiAnonymous,
        AssessmentGroupAnonymity.Anonymous,
      ].includes(assessment.template.anonymity) &&
        assessment.state !== AssessmentState.Submitted &&
        currentUser.id === responder?.id &&
        !isSelfAssessment && (
          <div className="border rounded-md border-gray-200 text-gray-500 py-3 px-4">
            <LockClosedIcon className="h-4 w-4 inline mr-1" /> Your responses
            will appear anonymous
            {assessment.template.anonymity ===
            AssessmentGroupAnonymity.SemiAnonymous
              ? " to the subject"
              : ""}
            . Anonymity will be affected if you put identifiable content in your
            comments.
          </div>
        )}
    </div>
  );
};

export default AssessmentHeader;
