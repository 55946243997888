import { useMutation, useQuery } from "@apollo/client";
import { CogIcon, PencilIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { Menu, MenuDivider, MenuItem } from "@szhsin/react-menu";
import { useCallback, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import {
  GetTopicTemplateQuery,
  GetTopicTemplateQueryVariables,
  NotesRequirement,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import { previousUrlVar } from "@cache/cache";
import Button from "@components/button/button";
import Label from "@components/label/label";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TemplateTopicWYSIWYG from "@components/wysiwyg/template-topic-wysiwyg";
import { assertNonNull, parseStringToJSON } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import deleteTopicTemplateMutation from "../graphql/delete-topic-template-mutation";
import getTemplateQuery from "../graphql/get-template-query";
import getTemplatesQuery from "../graphql/get-templates-query";
import { topicRequirementsOptions } from "./edit-topic";
import TemplateSidebar from "./template-sidebar";

const getTopicRequirementLabel = (
  isMandatory: boolean,
  notesRequirement: NotesRequirement
) => {
  const match =
    topicRequirementsOptions.find(
      (option) =>
        option.isMandatory === isMandatory &&
        notesRequirement === option.notesRequirement
    ) || topicRequirementsOptions[0];
  return match.label;
};

const TemplateView = () => {
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const [isShowingMeetingDialog, setIsShowingMeetingDialog] = useState(false);
  const { templateId } = useParams<{ templateId: string }>();
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this template?"
  );
  const prevUrl = previousUrlVar();
  const backUrl = location.state?.previousPathname || prevUrl || "/templates";

  const { data, loading } = useQuery<
    GetTopicTemplateQuery,
    GetTopicTemplateQueryVariables
  >(getTemplateQuery, {
    variables: { templateId: parseInt(templateId) },
    onError: onNotificationErrorHandler(),
  });
  const template = data?.topicTemplate;

  const [deleteTemplate] = useMutation(deleteTopicTemplateMutation);

  const handleDeleteTemplate = useCallback(async () => {
    const confirmation = await confirm();
    if (confirmation) {
      deleteTemplate({
        variables: { topicTemplateId: assertNonNull(template?.id) },
        refetchQueries: [getTemplatesQuery],
        onCompleted: () => {
          link.redirect(backUrl);
        },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [backUrl, confirm, deleteTemplate, link, template]);

  const handleClickUseTemplate = () => {
    setIsShowingMeetingDialog(true);
  };

  if (loading) {
    return (
      <div className="flex-1 flex justify-center p-10">
        <Loading>Loading template..</Loading>
      </div>
    );
  }
  if (!template) {
    return <Redirect to="/templates" />;
  }

  const topics = template.topicsList;
  return (
    <div className="sm:flex flex-1" aria-label="Template view">
      {isShowingMeetingDialog && (
        <MeetingDialog
          onClose={() => setIsShowingMeetingDialog(false)}
          formOptions={{
            isFormalOneonone: template.oneononeTemplate,
            templateId: template.id,
          }}
        />
      )}
      <ConfirmationDialog />
      <TemplateSidebar template={template} />
      <div className="flex-1 p-6 flex flex-col gap-4 bg-white">
        <div className="flex flex-col gap-6">
          <div className="flex justify-between items-center gap-2">
            <Button to={backUrl}>Back</Button>
            <div className="flex items-center gap-2">
              <Button
                tooltip="Use template to create meeting"
                className="hidden sm:flex"
                onClick={handleClickUseTemplate}
              >
                Use template
              </Button>
              {template.canUpdate.permission && (
                <Button
                  tooltip="Edit template"
                  className="hidden sm:flex"
                  to={`/templates/${template.id}/edit`}
                >
                  <PencilIcon className="h-5 w-5" />
                </Button>
              )}

              <Menu
                className="z-dropdown"
                align="end"
                portal
                transition
                aria-label="Template list"
                menuButton={
                  <Button aria-label="Template action dropdown button">
                    <CogIcon className="h-5 w-5" />
                  </Button>
                }
              >
                <MenuItem className="text-sm">
                  <AppLink to={`/templates/${template.id}/duplicate`}>
                    Duplicate template
                  </AppLink>
                </MenuItem>
                {template.canUpdate.permission && (
                  <MenuItem className="text-sm">
                    <AppLink to={`/templates/${template.id}/edit`}>
                      Edit template
                    </AppLink>
                  </MenuItem>
                )}
                <MenuDivider />
                <MenuItem className="text-sm">
                  <button onClick={handleClickUseTemplate}>Use template</button>
                </MenuItem>
                {template.canDelete.permission && <MenuDivider />}
                {template.canDelete.permission && (
                  <MenuItem
                    className="text-sm text-red-700"
                    onClick={handleDeleteTemplate}
                  >
                    Delete template
                  </MenuItem>
                )}
              </Menu>
            </div>
          </div>
          <div className="text-lg">Agenda</div>
          <div className="border rounded-lg px-6 py-2">
            {topics.length > 0 ? (
              <div className="flex flex-col divide-y divide-gray-100">
                {topics
                  .filter((topic) => topic !== null)
                  .map((topic) => (
                    <div
                      className="py-4 text-gray-600 flex flex-col gap-2"
                      key={topic.id}
                    >
                      <div className="flex gap-2">
                        <div className="text-gray-800 font-medium">
                          {topic.title}
                        </div>
                      </div>

                      <div className="flex gap-6 items-center p-1 text-xs">
                        {!template.oneononeTemplate &&
                          topic.includesSharedNotes && (
                            <div className="flex gap-2 items-center w-36">
                              <CheckIcon className="h-4 w-4 text-gray-400" />
                              Shared notes
                            </div>
                          )}
                        {(template.oneononeTemplate ||
                          topic.includesIndividualNotes) && (
                          <div className="flex gap-2 items-center text-violet-600  w-36">
                            <CheckIcon className="h-4 w-4" />
                            Individual notes
                          </div>
                        )}
                      </div>
                      {(waffle.flag_is_active(
                        "show-mandatory-dropdown-for-meeting-template"
                      ) ||
                        template.oneononeTemplate) && (
                        <div className="text-gray-400 text-xs">
                          {getTopicRequirementLabel(
                            topic.isMandatory,
                            topic.notesRequirement
                          )}
                        </div>
                      )}

                      {!template.oneononeTemplate &&
                        !isEmptyValue(
                          parseStringToJSON(topic.discussionNotes)
                        ) && (
                          <div>
                            <TemplateTopicWYSIWYG
                              key={`template-topic-${topic.id}`}
                              value={parseStringToJSON(topic.discussionNotes)}
                              className="text-base bg-gray-50/40 border rounded-lg py-1 px-4"
                              editable={false}
                              placeholder="Add default notes"
                              uploadVariable={{ topicId: topic.id }}
                              mentionsConfig={{}}
                              extraContext={{
                                organizationId: template.organization?.id,
                              }}
                            />
                          </div>
                        )}
                      {template.oneononeTemplate &&
                        (!isEmptyValue(
                          parseStringToJSON(topic.defaultFacilitatorNotes)
                        ) ||
                          !isEmptyValue(
                            parseStringToJSON(topic.defaultSubjectNotes)
                          )) && (
                          <>
                            <div className="mt-4">
                              <Label label="Facilitator default notes" />
                              <TemplateTopicWYSIWYG
                                key={`template-topic-facilitator-${topic.id}`}
                                value={parseStringToJSON(
                                  topic.defaultFacilitatorNotes
                                )}
                                className="text-base bg-gray-50/40 border rounded-lg py-1 px-4"
                                editable={false}
                                placeholder="Default notes for facilitator"
                                uploadVariable={{ topicId: topic.id }}
                                mentionsConfig={{}}
                                extraContext={{
                                  organizationId: template.organization?.id,
                                }}
                              />
                            </div>
                            <div className="mt-1">
                              <Label label="Subject default notes" />
                              <TemplateTopicWYSIWYG
                                key={`template-topic-subject-${topic.id}`}
                                value={parseStringToJSON(
                                  topic.defaultSubjectNotes
                                )}
                                className="text-base bg-gray-50/40 border rounded-lg py-1 px-4"
                                editable={false}
                                placeholder="Default notes for subject"
                                uploadVariable={{ topicId: topic.id }}
                                mentionsConfig={{}}
                                extraContext={{
                                  organizationId: template.organization?.id,
                                }}
                              />
                            </div>
                          </>
                        )}
                    </div>
                  ))}
              </div>
            ) : (
              <div className="text-gray-500 text-sm">
                This template has no topics.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateView;
