import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";

export default gql`
  ${CommonGoalArtifactFragment}
  fragment GetAssessmentAsessmentGroupSection on AssessmentSectionInterface {
    id
    title
    description
    questions {
      edges {
        node {
          id
          question {
            ... on IndividualGoalAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
              goal {
                ...CommonGoalArtifactFragment
              }
            }
            ... on OverallGoalAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
            }
            ... on RangeAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
            }
            ... on CompetencyAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
              role {
                id
                title
                level
              }
              competency {
                id
                criteria {
                  edges {
                    node {
                      id
                      content
                    }
                  }
                }
              }
            }
            ... on CompetencyCriteriaAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
              role {
                id
                title
                level
              }
              competencyCriteria {
                id
                content
                competency {
                  id
                  title
                  description
                }
              }
            }
            ... on ResponsibilityAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
              responsibility {
                id
                content
                role {
                  id
                  title
                  level
                }
              }
            }
            ... on MultiChoiceAssessmentQuestionNode {
              id
              title
              description
              options
              optionDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
            }
            ... on TextAssessmentQuestionNode {
              id
              title
              description
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
            }
          }
        }
      }
    }
  }
  query getAssessment($assessmentId: Int!, $organizationId: Int!) {
    assessment(assessmentId: $assessmentId) {
      id
      state
      submittedDatetime
      periodStartDate
      periodEndDate
      dueDate
      targetCurrentRoleIds
      canUpdate {
        permission
        reason
      }
      complianceProgram {
        id
        title
        canReadDetails {
          permission
          reason
        }
      }
      template {
        id
        assessmentType
        delivery
        anonymity
        roleBasedAssessmentsAssessNextRole
        roleBasedAssessmentsSelfAssessNextRole
        questionSets {
          edges {
            node {
              id
              title
              sections {
                edges {
                  node {
                    ...GetAssessmentAsessmentGroupSection
                  }
                }
              }
            }
          }
        }
      }
      additionalQuestionSets {
        edges {
          node {
            id
            title
            sections {
              edges {
                node {
                  ...GetAssessmentAsessmentGroupSection
                }
              }
            }
          }
        }
      }
      responder {
        id
        name
        avatar
      }
      target {
        id
        name
        email
        firstName
        avatar
        organizationSpecificInfo(organizationId: $organizationId) {
          position
          jobLevel
        }
      }
      answers {
        edges {
          node {
            ... on RangeAssessmentAnswerNode {
              id
              questionId
              integerAnswer
              comment
            }
            ... on IndividualGoalAssessmentAnswerNode {
              id
              questionId
              integerAnswer
              comment
            }
            ... on OverallGoalAssessmentAnswerNode {
              id
              questionId
              integerAnswer
              comment
            }
            ... on ResponsibilityAssessmentAnswerNode {
              id
              questionId
              integerAnswer
              comment
            }
            ... on CompetencyAssessmentAnswerNode {
              id
              questionId
              integerAnswer
              comment
            }
            ... on CompetencyCriteriaAssessmentAnswerNode {
              id
              questionId
              integerAnswer
              comment
            }
            ... on MultiChoiceAssessmentAnswerNode {
              id
              questionId
              choices
              comment
            }
            ... on TextAssessmentAnswerNode {
              id
              questionId
              textAnswer
              comment
            }
          }
        }
      }
      relatedMeetings {
        edges {
          node {
            id
            title
            meetingGroupId
            startDatetime
            endDatetime
            draft
          }
        }
      }
      relatedAssessments {
        edges {
          node {
            id
            state
            submittedDatetime
            responder {
              id
              name
              avatar
            }
            target {
              id
              name
              avatar
            }
            template {
              id
              assessmentType
            }
          }
        }
      }
    }
  }
`;
