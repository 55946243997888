import {
  ComplianceProgramParticipantStatus,
  ComplianceProgramReportParticipantStatusFragment,
  ComplianceProgramReportParticipantStatusUserFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import AppLink from "@components/link/link";
import ManagedTable from "@components/table/managed-table";
import {
  UserComboboxOptionType,
  UserComboboxOrgOption,
  UserComboboxTeamOption,
} from "@components/user-combobox/user-combobox-list";

import {
  complianceProgramReportingTableColNameClassName,
  complianceProgramReportingTableColsClassName,
} from "./compliance-program-progress";
import ProgramProgressBar from "./program-progress-bar";

export type ComplianceProgramReportingEntityTableParticipantStatusWithNominations =
  ComplianceProgramReportParticipantStatusFragment & {
    nominations: ComplianceProgramParticipantStatus;
  };

export type ComplianceProgramReportingEntityTableKeyName =
  | keyof Omit<
      ComplianceProgramReportingEntityTableParticipantStatusWithNominations,
      "__typename" | "user" | "meeting"
    >;

export type ComplianceProgramReportingEntityTableCol = {
  label: string;
  keyName: ComplianceProgramReportingEntityTableKeyName;
  remindUsers?: ComplianceProgramReportParticipantStatusUserFragment[];
  remindUserTooltip?: string;
  remindUserType?: "manager" | "subject" | "peer" | "directReport";
};

type ComplianceProgramReportingEntityTableStatusCount = {
  [key in ComplianceProgramParticipantStatus]: number;
};

const defaultStatusCount: ComplianceProgramReportingEntityTableStatusCount = {
  [ComplianceProgramParticipantStatus.NotApplicable]: 0,
  [ComplianceProgramParticipantStatus.NotStarted]: 0,
  [ComplianceProgramParticipantStatus.InProgress]: 0,
  [ComplianceProgramParticipantStatus.Complete]: 0,
};

type ComplianceProgramReportingTableEntity = (
  | UserComboboxOrgOption
  | UserComboboxTeamOption
) & {
  participantStatuses: ComplianceProgramReportingEntityTableParticipantStatusWithNominations[];
};

const ComplianceProgramProgressEntityTable = ({
  complianceProgramId,
  entities,
  cols,
}: {
  complianceProgramId: number;
  entities: ComplianceProgramReportingTableEntity[];
  cols: ComplianceProgramReportingEntityTableCol[];
}) => {
  const label = useLabel();
  const emptyStatusCountByCol = cols.reduce((memo, col) => {
    return {
      ...memo,
      [col.keyName]: defaultStatusCount,
    };
  }, {} as Record<ComplianceProgramReportingEntityTableKeyName, ComplianceProgramReportingEntityTableStatusCount>);

  return entities.length === 0 ? (
    <div className="text-gray-500 text-sm">
      No {label("team")} members participating to this {label("program")}.
    </div>
  ) : (
    <ManagedTable
      scroll
      sortingEnabled
      sortDir="asc"
      sortKey="name"
      data={entities}
      className="overscroll-x-contain"
      columns={[
        {
          header: `Name`,
          headerClassName: complianceProgramReportingTableColNameClassName,
          cellClassName: complianceProgramReportingTableColNameClassName,
          render: (data: ComplianceProgramReportingTableEntity) => {
            return (
              <AppLink
                to={`/programs/${complianceProgramId}/progress?${data.type}=${data.id}`}
                className="font-medium hover:underline"
              >
                {data.type === UserComboboxOptionType.TEAM
                  ? data.title
                  : data.name}
              </AppLink>
            );
          },
          sortKey: "name",
          value: (data: ComplianceProgramReportingTableEntity) => {
            return data.type === UserComboboxOptionType.TEAM
              ? data.title
              : data.name;
          },
        },
        ...cols.map((col) => ({
          header: col.label,
          headerClassName: complianceProgramReportingTableColsClassName,
          cellClassName: complianceProgramReportingTableColsClassName,
          sortKey: col.keyName,
          value: (data: ComplianceProgramReportingTableEntity) => {
            const statusCountByCol = data.participantStatuses.reduce(
              (memo, participantStatus) => {
                return cols.reduce((memo2, col) => {
                  const currentValue = memo2[col.keyName];
                  return {
                    ...memo2,
                    [col.keyName]: {
                      ...currentValue,
                      [participantStatus[col.keyName]]:
                        memo2[col.keyName][participantStatus[col.keyName]] + 1,
                    },
                  };
                }, memo);
              },
              emptyStatusCountByCol as Record<
                ComplianceProgramReportingEntityTableKeyName,
                ComplianceProgramReportingEntityTableStatusCount
              >
            );
            return (
              statusCountByCol[col.keyName][
                ComplianceProgramParticipantStatus.Complete
              ] *
                1000000000 +
              statusCountByCol[col.keyName][
                ComplianceProgramParticipantStatus.InProgress
              ] *
                1000000 +
              statusCountByCol[col.keyName][
                ComplianceProgramParticipantStatus.NotStarted
              ] *
                1000 +
              statusCountByCol[col.keyName][
                ComplianceProgramParticipantStatus.NotApplicable
              ]
            );
          },
          render: (data: ComplianceProgramReportingTableEntity) => {
            const statusCountByCol = data.participantStatuses.reduce(
              (memo, participantStatus) => {
                return cols.reduce((memo2, col) => {
                  const currentValue = memo2[col.keyName];
                  return {
                    ...memo2,
                    [col.keyName]: {
                      ...currentValue,
                      [participantStatus[col.keyName]]:
                        memo2[col.keyName][participantStatus[col.keyName]] + 1,
                    },
                  };
                }, memo);
              },
              emptyStatusCountByCol as Record<
                ComplianceProgramReportingEntityTableKeyName,
                ComplianceProgramReportingEntityTableStatusCount
              >
            );
            return (
              <ProgramProgressBar
                className="w-full"
                notStarted={{
                  value:
                    statusCountByCol[col.keyName][
                      ComplianceProgramParticipantStatus.NotStarted
                    ],
                  label:
                    col.keyName === "oneononeStatus"
                      ? "Not Scheduled"
                      : "Not Started",
                }}
                inProgress={{
                  value:
                    statusCountByCol[col.keyName][
                      ComplianceProgramParticipantStatus.InProgress
                    ],
                  label:
                    col.keyName === "oneononeStatus"
                      ? "Scheduled"
                      : "In Progress",
                }}
                complete={{
                  value:
                    statusCountByCol[col.keyName][
                      ComplianceProgramParticipantStatus.Complete
                    ],
                  label:
                    col.keyName === "oneononeStatus" ? "Finalized" : "Complete",
                }}
              />
            );
          },
        })),
        {
          header: ``,
          render: () => "",
        },
      ]}
    />
  );
};

export default ComplianceProgramProgressEntityTable;
