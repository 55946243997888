import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { previousUrlVar } from "@cache/cache";

const TrackPreviousUrl = () => {
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;
  const prevUrlRef = useRef<null | string>(null);
  useEffect(() => {
    previousUrlVar(prevUrlRef.current || null);
    prevUrlRef.current = currentUrl;
  }, [currentUrl]);

  return null;
};

export default TrackPreviousUrl;
