import { gql } from "@apollo/client";

export default gql`
  mutation saveAssessmentGroup(
    $title: String
    $organizationId: Int
    $assessmentGroupId: Int
    $sections: [AssessmentGroupSectionInput]
    $archived: Boolean
  ) {
    createOrUpdateAssessmentGroup(
      organizationId: $organizationId
      assessmentGroupId: $assessmentGroupId
      title: $title
      sections: $sections
      archived: $archived
    ) {
      assessmentGroup {
        id
      }
    }
  }
`;
