import { useEffect, useState } from "react";

import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";

import { AssessmentAnswer } from "./types";

const TextAssessmentQuestionContent = ({
  answer,
  disabled,
  onChangeAnswer,
}: {
  answer?: AssessmentAnswer | null;
  disabled?: boolean;
  onChangeAnswer: (newAnswer: Partial<AssessmentAnswer>) => void;
}) => {
  // By default answers are not saved and don't have an id,
  // so when rendered them for the first time, the id & content is undefined.
  // Once the user types text, we save it to the DB.
  // When editing a draft answer, it initially comes as undefined then once we have the api response with answers
  // it passes the id and text content therefore we need to update the wysiwyg key or deps to re-render the component.
  // Here we optimize so we re-render the wysiwyg only when user is not focused on it to prevent losing focus while typing.
  const [isFocused, setIsFocused] = useState(false);
  const answerDependencyId = answer?.questionId ? answer?.questionId : 0;
  const [dependencyId, setDependencyId] = useState(answerDependencyId);

  useEffect(() => {
    if (!isFocused) {
      // don't update the dependency if user is focused on field.
      setDependencyId(answerDependencyId);
    }
  }, [isFocused, answerDependencyId]);

  return (
    <TextareaWysiwyg
      editable={!disabled}
      className="bg-white"
      value={answer?.textAnswer ?? "{}"}
      onChangeValue={(text) =>
        onChangeAnswer({
          textAnswer: text,
        })
      }
      placeholder="Write your answer here..."
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      deps={[dependencyId]}
    />
  );
};

export default TextAssessmentQuestionContent;
