import { useMutation } from "@apollo/client";
import { compact } from "lodash";
import { useCallback, useState } from "react";
import {
  AssessmentDeliveryState,
  AssessmentGroupDelivery,
  AssessmentState,
  ComplianceProgramReportAnswerFragment,
  ComplianceProgramReportAssessmentFragment,
  ComplianceProgramTargetFragment,
  GetComplianceProgramForReportQuery,
  SaveAssessmentDeliveryMutation,
  SaveAssessmentDeliveryMutationVariables,
  UnsubmitAssessmentMutation,
  UnsubmitAssessmentMutationVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import CalibrationChangelog from "@apps/assessments/components/calibration-changelog/calibration-changelog";
import reopenAssessmentMutation from "@apps/assessments/graphql/reopen-assessment-mutation";
import updateAssessmentDeliveryMutation from "@apps/assessments/graphql/update-assessment-delivery-mutation";
import getComplianceProgramForReportQuery from "@apps/reporting/graphql/get-compliance-program-for-report-query";
import useLabel from "@apps/use-label/use-label";
import { isAdminVar, successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import AppLabel from "@components/draft-label/app-label";
import Dropdown, {
  DropdownOptionType,
  removeConsecutiveDividers,
} from "@components/dropdown/dropdown";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { TableBodyCell, TableBodyRow } from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import { PerformanceAssessmentTableQuestion } from "./performance-assessment-table";
import PerformanceAssessmentTableRowAnswerCell from "./performance-assessment-table-row-answer-cell";

const PerformanceAssessmentTableRow = ({
  assessment,
  target,
  responder,
  answers,
  questions,
  assessmentTemplate,
  showRoleColumn,
  showAdminApprovalStatus,
}: {
  showAdminApprovalStatus: boolean;
  assessment: ComplianceProgramReportAssessmentFragment;
  target: ComplianceProgramTargetFragment;
  responder: BasicUser;
  answers: ComplianceProgramReportAnswerFragment[];
  questions: PerformanceAssessmentTableQuestion[];
  assessmentTemplate: NonNullable<
    NonNullable<
      GetComplianceProgramForReportQuery["complianceProgram"]
    >["performanceAssessmentTemplate"]
  >;
  showRoleColumn: boolean;
}) => {
  const [
    isJustificationChangelogModalOpen,
    setIsJustificationChangelogModalOpen,
  ] = useState(false);
  const label = useLabel();
  const isAdmin = isAdminVar();
  const teams = assertEdgesNonNull(target.teams);
  const currentRoles = assertEdgesNonNull(assessment.currentRoles);
  const isAssessmentWithAdminCalibration =
    waffle.flag_is_active("calibration") &&
    assessmentTemplate.delivery === AssessmentGroupDelivery.AdminApproval;
  const assessmentDeliveryState = assessment.assessmentDelivery
    ? assessment.assessmentDelivery.state
    : assessment.assessmentDeliveryState;
  const canAdminCalibrate =
    isAdmin &&
    isAssessmentWithAdminCalibration &&
    assessmentDeliveryState === AssessmentDeliveryState.AwaitingAdminApproval;
  const canUpdate =
    assessment.assessmentDelivery &&
    !!assessment.assessmentDelivery.canUpdate?.permission;
  const canReadAssessment = !!assessment.canRead?.permission;
  const canReadAssessmentDelivery =
    assessment.assessmentDelivery &&
    !!assessment.assessmentDelivery.canRead?.permission;
  const [saveAssessmentDelivery] = useMutation<
    SaveAssessmentDeliveryMutation,
    SaveAssessmentDeliveryMutationVariables
  >(updateAssessmentDeliveryMutation);

  const [reopenAssessment] = useMutation<
    UnsubmitAssessmentMutation,
    UnsubmitAssessmentMutationVariables
  >(reopenAssessmentMutation);

  const {
    ConfirmationDialog: AdminApprovalConfirmationDialog,
    confirm: confirmAdminApproval,
  } = useConfirm(
    "Are you sure?",
    `Proceeding will approve this delivery package and allow the manager of ${assessment.target?.name} to share it whenever they choose. Do you want to continue?`
  );
  const handleClickApprove = useCallback(async () => {
    const confirmation = await confirmAdminApproval();
    if (confirmation && assessment.assessmentDelivery) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId: assessment.assessmentDelivery.id,
          state: AssessmentDeliveryState.Draft,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package approved`,
          });
        },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [confirmAdminApproval, saveAssessmentDelivery, assessment, label]);

  const {
    ConfirmationDialog: DeliverConfirmationDialog,
    confirm: confirmDeliver,
  } = useConfirm(
    "Are you sure?",
    `Proceeding will share the content of the ${label("review")} with ${
      assessment.target?.name
    }. Do you want to continue?`
  );
  const handleClickDeliver = useCallback(async () => {
    const confirmation = await confirmDeliver();
    if (confirmation && assessment.assessmentDelivery) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId: assessment.assessmentDelivery.id,
          state: AssessmentDeliveryState.Delivered,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package delivered`,
          });
        },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [confirmDeliver, saveAssessmentDelivery, assessment, label]);

  const {
    ConfirmationDialog: ReopenConfirmationDialog,
    confirm: confirmReopen,
  } = useConfirm(
    "Are you sure?",
    `Reopening the ${label(
      "review"
    )} will allow the responder to edit their responses.. Do you want to continue?`
  );
  const handleClickSendBackSubmissionToManager = useCallback(async () => {
    const confirmation = await confirmReopen();
    if (confirmation && assessment) {
      reopenAssessment({
        variables: {
          assessmentId: assessment.id,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} reopened. The responder can now edit their responses.`,
          });
        },
        refetchQueries: [getComplianceProgramForReportQuery],
        onError: onNotificationErrorHandler(),
      });
    }
  }, [confirmReopen, assessment, reopenAssessment, label]);

  const {
    ConfirmationDialog: UnapproveConfirmationDialog,
    confirm: confirmUnapprove,
  } = useConfirm(
    "Are you sure?",
    `Undoing admin approval will prevent manager from sharing the ${label(
      "review"
    )}. It will allow admins to calibrate the ${label(
      "review"
    )} responses. Do you want to continue?`
  );
  const handleClickUndoAdminApproval = useCallback(async () => {
    const confirmation = await confirmUnapprove();
    if (confirmation && assessment.assessmentDelivery) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId: assessment.assessmentDelivery.id,
          state: AssessmentDeliveryState.AwaitingAdminApproval,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package un-approved`,
          });
        },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [confirmUnapprove, saveAssessmentDelivery, assessment, label]);

  const {
    ConfirmationDialog: UndoDeliverConfirmationDialog,
    confirm: confirmUndoDelivery,
  } = useConfirm(
    "Are you sure?",
    `Undoing delivery of the review will prevent manager from sharing the ${label(
      "review"
    )} with their report. Do you want to continue?`
  );
  const handleClickUndoDelivery = useCallback(async () => {
    const confirmation = await confirmUndoDelivery();
    if (confirmation && assessment.assessmentDelivery) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId: assessment.assessmentDelivery.id,
          state: AssessmentDeliveryState.Draft,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package un-delivered`,
          });
        },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [confirmUndoDelivery, saveAssessmentDelivery, assessment, label]);

  const hasCalibrationChanges = answers.some((answer) =>
    answer.__typename === "RangeAssessmentAnswerNode"
      ? answer.hasCalibrations
      : false
  );

  const actionDropdownOptions: DropdownOptionType[] = removeConsecutiveDividers(
    compact([
      {
        type: "divider",
      },
      canUpdate &&
        assessmentDeliveryState === AssessmentDeliveryState.Draft && {
          label: `Deliver ${label("review", { capitalize: true })} Package`,
          onClick: handleClickDeliver,
          type: "button",
        },
      canUpdate &&
        isAdmin &&
        assessmentDeliveryState ===
          AssessmentDeliveryState.AwaitingAdminApproval && {
          label: `Approve ${label("review", { capitalize: true })} Package`,
          onClick: handleClickApprove,
          type: "button",
        },
      canUpdate &&
        isAdmin &&
        assessmentDeliveryState === AssessmentDeliveryState.Delivered && {
          label: `Revert ${label("review", {
            capitalize: true,
          })} Package Delivery`,
          onClick: handleClickUndoDelivery,
          type: "button",
        },
      canUpdate &&
        isAdmin &&
        assessmentDeliveryState === AssessmentDeliveryState.Draft && {
          label: `Undo Admin Approval`,
          onClick: handleClickUndoAdminApproval,
          type: "button",
        },
      canUpdate &&
        isAdmin &&
        assessmentDeliveryState ===
          AssessmentDeliveryState.AwaitingAdminApproval && {
          label: `Send Back to Manager`,
          onClick: handleClickSendBackSubmissionToManager,
          type: "button",
        },
      { type: "divider" },
      isAssessmentWithAdminCalibration &&
        hasCalibrationChanges && {
          label: `View Change Log`,
          onClick: () => setIsJustificationChangelogModalOpen(true),
          type: "button",
        },
      { type: "divider" },
      canReadAssessment && {
        label: `View ${label("review", { capitalize: true })} Submission`,
        to: `/assessments/assessment/${assessment.id}`,
        type: "link",
      },
      canReadAssessmentDelivery && {
        label: `View ${label("review", { capitalize: true })} Package`,
        to: `/assessments/assessment/delivery/${assessment.assessmentDelivery.id}`,
        type: "link",
      },
    ])
  );

  return (
    <TableBodyRow key={assessment.id}>
      <TableBodyCell>
        <div className="flex items-center">
          <Avatar className="mr-2" size="6" user={target} /> {target.name}
        </div>

        {isJustificationChangelogModalOpen && (
          <Modal onClose={() => setIsJustificationChangelogModalOpen(false)}>
            <div className="flex flex-col divide-y">
              <div className="p-6">
                <ModalTitle
                  onClose={() => setIsJustificationChangelogModalOpen(false)}
                >
                  <div className="flex items-center gap-4">
                    <Avatar user={target} size="6" />
                    {target.name}'s {label("review", { capitalize: true })}{" "}
                    Change Log
                  </div>
                </ModalTitle>
              </div>
              <CalibrationChangelog
                assessmentId={assessment.id}
                className="p-6"
              />
            </div>
          </Modal>
        )}
      </TableBodyCell>
      {showAdminApprovalStatus && (
        <TableBodyCell>
          <div className="min-w-0 flex flex-nowrap grow shrink-0 items-center justify-between gap-2">
            <AppLabel
              tooltip={
                assessmentDeliveryState === AssessmentDeliveryState.Draft
                  ? `The ${label(
                      "review"
                    )} was approved by an admin. The manager can now deliver it with the subject.`
                  : assessmentDeliveryState ===
                    AssessmentDeliveryState.AwaitingAdminApproval
                  ? `The ${label("review")} is awaiting approval by an admin.`
                  : assessmentDeliveryState ===
                    AssessmentDeliveryState.Delivered
                  ? `The ${label("review")} was delivered to the subject.`
                  : assessment.state === AssessmentState.Submitted
                  ? `The ${label("review")} was submitted by the manager.`
                  : `The ${label("review")} is in draft state.`
              }
              label={
                // Draft is considered approved when delivery is set to AdminApproval
                assessmentDeliveryState === AssessmentDeliveryState.Draft
                  ? "Approved"
                  : assessmentDeliveryState ===
                    AssessmentDeliveryState.AwaitingAdminApproval
                  ? "Awaiting Approval"
                  : assessmentDeliveryState ===
                    AssessmentDeliveryState.Delivered
                  ? "Delivered"
                  : assessment.state === AssessmentState.Submitted
                  ? "Submitted"
                  : "Draft"
              }
              className={classNames(
                assessmentDeliveryState === AssessmentDeliveryState.Delivered
                  ? "bg-emerald-100 text-emerald-700"
                  : assessmentDeliveryState === AssessmentDeliveryState.Draft
                  ? "bg-blue-100 text-blue-600"
                  : ""
              )}
            />
            {actionDropdownOptions.length > 0 && (
              <Dropdown align="end" options={actionDropdownOptions} />
            )}
            <UndoDeliverConfirmationDialog />
            <UnapproveConfirmationDialog />
            <AdminApprovalConfirmationDialog />
            <DeliverConfirmationDialog />
            <ReopenConfirmationDialog />
          </div>
        </TableBodyCell>
      )}
      {showRoleColumn && (
        <TableBodyCell>
          {currentRoles
            .map((role) => `${role.title} (${role.careerTrack.title})`)
            .join(", ")}
        </TableBodyCell>
      )}
      <TableBodyCell>{responder.name}</TableBodyCell>
      <TableBodyCell>
        {teams.map(({ title }) => title).join(", ")}
      </TableBodyCell>
      {questions.map((question) => {
        return (
          <PerformanceAssessmentTableRowAnswerCell
            key={question.id}
            question={question}
            answers={answers}
            responder={responder}
            canAdminCalibrate={canAdminCalibrate}
          />
        );
      })}
    </TableBodyRow>
  );
};

export default PerformanceAssessmentTableRow;
