import { flatMap, range } from "lodash";
import {
  AssessmentDeliveryFragment,
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionResponses,
  AssessmentQuestionType,
} from "types/graphql-schema";

import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import { AssessmentQuestion } from "./components/assessment-question-form";
import { AssessmentSection } from "./components/questions/types";

export const bgClassName = "bg-white rounded-lg shadow-md";

export const ASSESSMENT_QUESTION_SCALE_MIN = 1;
export const ASSESSMENT_QUESTION_SCALE_MAX = 10;

export const getAssessmentQuestionScale = (question: AssessmentQuestion) => {
  const scaleStartValue = Math.max(1, question.startValue ?? 1);
  const scaleEndValue = Math.min(
    ASSESSMENT_QUESTION_SCALE_MAX,
    question.endValue ?? 5
  );
  return {
    scaleEndValue,
    scaleStartValue,
    scaleDiff: scaleEndValue - scaleStartValue + 1,
  };
};

const blankQuestionValueCount = 5;
export const emptyAssessmentQuestion = (
  questionType: AssessmentQuestionType
) => ({
  title: "",
  description: null,
  startValue: questionType === AssessmentQuestionType.Range ? 1 : undefined,
  endValue:
    questionType === AssessmentQuestionType.Range
      ? blankQuestionValueCount
      : undefined,
  labels:
    questionType === AssessmentQuestionType.Range
      ? range(0, blankQuestionValueCount).map(() => "")
      : undefined,
  labelDescriptions:
    questionType === AssessmentQuestionType.Range
      ? range(0, blankQuestionValueCount).map(() => "")
      : undefined,
  questionType: AssessmentQuestionType.Range,
  isCommentMandatory: false,
  responses: AssessmentQuestionResponses.Both,
  responseVisibility: AssessmentQuestionResponseVisibility.All,
  optionCount:
    questionType === AssessmentQuestionType.Multichoice ? 2 : undefined,
  options:
    questionType === AssessmentQuestionType.Multichoice
      ? ["Option 1", "Option 2"]
      : undefined,
  optionDescriptions:
    questionType === AssessmentQuestionType.Multichoice ? ["", ""] : undefined,
  categories: [],
});

export const getValidAssessmentQuestionMessage = (
  proposedQuestion: AssessmentQuestion
) => {
  const optionsMissingValues = range(0, proposedQuestion.optionCount)
    .map((x) => {
      return proposedQuestion.options?.[x] || "";
    })
    .some((opt) => opt?.trim() === "");
  return proposedQuestion.title.trim().length === 0
    ? "Please enter a title "
    : proposedQuestion.questionType === AssessmentQuestionType.Multichoice &&
      optionsMissingValues
    ? "All options must have values"
    : null;
};

export const sortedQuestionCategoryTitles = (
  categories: { title: string }[]
) => {
  return categories
    .map(({ title }) => title)
    .sort((a, b) => a.localeCompare(b))
    .join(", ");
};

export const getAssessmentDeliverySections = (
  assessmentDeliveryData?: AssessmentDeliveryFragment | null
) => {
  if (!assessmentDeliveryData) {
    return [];
  }

  const assessmentTemplate = assertNonNull(assessmentDeliveryData.template);
  const additionalQuestionSets = assertEdgesNonNull(
    assessmentDeliveryData.additionalQuestionSets
  );
  const addtionalSections = flatMap(additionalQuestionSets, (set) => {
    const newSections: AssessmentSection[] = assertEdgesNonNull(
      set.sections
    ).map((section) => {
      const questions = assertEdgesNonNull(section.questions);
      return {
        id: section.id,
        title: section.title,
        description: section.description,
        questions,
      };
    });
    return newSections;
  });
  const questionSets = assertEdgesNonNull(assessmentTemplate.questionSets);
  const regularSections = flatMap(questionSets, (set) => {
    const newSections: AssessmentSection[] = assertEdgesNonNull(
      set.sections
    ).map((section) => {
      const questions = assertEdgesNonNull(section.questions);
      return {
        id: section.id,
        title: section.title,
        description: section.description,
        questions,
      };
    });
    return newSections;
  });
  return [...addtionalSections, ...regularSections];
};

export const getHiddenQuestionCount = (
  assessmentDelivery: AssessmentDeliveryFragment
) => {
  const sections = getAssessmentDeliverySections(assessmentDelivery);
  const questions = flatMap(sections, (section) => section.questions);
  const answers = assertEdgesNonNull(assessmentDelivery.answers);
  return questions.filter(
    (node) =>
      node.question.responseVisibility ===
        AssessmentQuestionResponseVisibility.HiddenFromSubject &&
      answers.some((answer) => answer.questionId === node.question.id)
  ).length;
};
