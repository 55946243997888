import { Fragment } from "react";
import { AssessmentQuestionResponses } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";

import AssessmentLayout from "../layouts/assessment-layout";
import AssessmentQuestionItem from "../questions/assessment-question-item";
import {
  AssessmentAnswer,
  AssessmentQuestionContainer,
} from "../questions/types";
import { OverallGoalAssessmentQuestionDescriptionContext } from "./overall-goal-assessment-question-description";

const GoalBasedQuestions = ({
  individualGoalBasedQuestions,
  overallGoalBasedQuestion,
  isSelfAssessment,
  proposedAnswers,
  formDisabled,
  showErrors,
  onUpdateAnswer,
}: {
  isSelfAssessment: boolean;
  overallGoalBasedQuestion?: AssessmentQuestionContainer;
  individualGoalBasedQuestions: AssessmentQuestionContainer[];
  proposedAnswers: AssessmentAnswer[];
  formDisabled: boolean;
  showErrors: boolean;
  onUpdateAnswer: (answer: AssessmentAnswer) => void;
}) => {
  const label = useLabel();
  const overallGoalBasedQuestionAnswer = proposedAnswers.find(
    (answer) =>
      overallGoalBasedQuestion &&
      answer.questionId === overallGoalBasedQuestion.id
  );
  const sumAnswersOfIndividualGoalBasedQuestions =
    individualGoalBasedQuestions.reduce((sum, question) => {
      const answer = proposedAnswers.find(
        (answer) => answer.questionId === question.id
      );
      if (!answer || !answer.integerAnswer) return sum;
      return sum + answer.integerAnswer;
    }, 0);
  const averageScoreOfIndividualGoalBasedQuestions =
    individualGoalBasedQuestions.length === 0 ||
    sumAnswersOfIndividualGoalBasedQuestions === 0
      ? 0
      : sumAnswersOfIndividualGoalBasedQuestions /
        individualGoalBasedQuestions.length;

  return (
    <>
      <AssessmentLayout.SectionHeading>
        <AssessmentLayout.SectionHeadingTitle>
          {label("goal", { pluralize: true, capitalize: true })}
        </AssessmentLayout.SectionHeadingTitle>
        <AssessmentLayout.SectionHeadingDescription>
          Assess the {label("goal", { pluralize: true })} due in this{" "}
          {label("review")} cycle
        </AssessmentLayout.SectionHeadingDescription>
      </AssessmentLayout.SectionHeading>

      <AssessmentLayout.SectionRegularQuestions>
        {individualGoalBasedQuestions.length === 0 && (
          <div className="p-6">
            <div className="text-sm text-gray-500">
              No {label("goal", { pluralize: true })} to assess.
            </div>
          </div>
        )}
        {individualGoalBasedQuestions.map((questionWithWeight, index) => {
          const { question } = questionWithWeight;
          if (
            question.responses ===
              AssessmentQuestionResponses.ExcludeSelfAssessment &&
            isSelfAssessment
          ) {
            return null;
          }
          if (
            question.responses ===
              AssessmentQuestionResponses.SelfAssessmentOnly &&
            !isSelfAssessment
          ) {
            return null;
          }
          const answer = proposedAnswers.find(
            (answer) => answer.questionId === question.id
          );
          return (
            <AssessmentQuestionItem
              key={question.id}
              answer={answer}
              question={question}
              onUpdateAnswer={onUpdateAnswer}
              formDisabled={formDisabled}
              showError={showErrors}
            />
          );
        })}
        {individualGoalBasedQuestions.length > 0 && overallGoalBasedQuestion && (
          <div>
            <OverallGoalAssessmentQuestionDescriptionContext.Provider
              value={{
                averageScoreOfIndividualGoalBasedQuestions: String(
                  averageScoreOfIndividualGoalBasedQuestions.toFixed(1)
                ).replace(".0", ""),
              }}
            >
              <AssessmentQuestionItem
                key={overallGoalBasedQuestion.id}
                answer={overallGoalBasedQuestionAnswer}
                question={overallGoalBasedQuestion.question}
                onUpdateAnswer={onUpdateAnswer}
                formDisabled={formDisabled}
                showError={showErrors}
              />
            </OverallGoalAssessmentQuestionDescriptionContext.Provider>
          </div>
        )}
      </AssessmentLayout.SectionRegularQuestions>
    </>
  );
};

export default GoalBasedQuestions;
